import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  ProblemDetails,
  PasswordPolicyViewModel,
} from '../../api/axcloud';
import {
  createContractTenantApi,
  isAxiosErrorProblemDetails,
} from '../../api';
import {
  logout,
} from "../login/loginSlice";

interface ResolvePasswordPolicyArgType {
  /** アクセストークン */
  accessToken: string;
}

/**
 * 大臣AXクラウドのパスワードポリシーを取得
 */
export const resolvePasswordPolicy = createAsyncThunk<
	PasswordPolicyViewModel,
  ResolvePasswordPolicyArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "security/resolvePasswordPolicy",
  async (arg, thunk) => {
    const api = createContractTenantApi(arg.accessToken);
    try {
      const response = await api.getPasswordPolicy();
      return response.data;
    } catch (error) {
      if (isAxiosErrorProblemDetails(error)) {
        return thunk.rejectWithValue(error.response!.data);
      } else {
        throw error;
      }
    }
  }
);

interface ResetAdministratorPasswordArgType {
  /** アクセストークン */
  accessToken: string;
  /** 新しいパスワード */
	newPassword: string;
  /** 新しいパスワード(確認用) */
  newPasswordConfirmation: string;
}

/**
 * 大臣AXクラウドのシステム管理者のパスワードをリセット
 */
export const resetAdministratorPassword = createAsyncThunk<
  void,
  ResetAdministratorPasswordArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "security/resetAdministratorPassword",
  async (arg, thunk) => {
    const api = createContractTenantApi(arg.accessToken);
    try {
      const response = await api.resetAdministratorPassword({
        newPassword: arg.newPassword,
        newPasswordConfirmation: arg.newPasswordConfirmation,
      });
      return response.data;
    } catch (error) {
      if (isAxiosErrorProblemDetails(error)) {
        return thunk.rejectWithValue(error.response!.data);
      } else {
        throw error;
      }
    }
  }
);

export interface SecurityState {
  /** パスワードポリシー */
	passwordPolicy?: PasswordPolicyViewModel | null;
}

const initialState: SecurityState = {};

export const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    clearPasswordPolicy: (state) => ({
      ...state,
      passwordPolicy: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(resolvePasswordPolicy.fulfilled, (state, action) => {
      return {
        ...state,
				passwordPolicy: action.payload,
      };
    });
    builder.addCase(logout.fulfilled, (state, action) => ({
      ...initialState,
    }));
  },
});

export const {
  clearPasswordPolicy,
} = securitySlice.actions;

export default securitySlice.reducer;
