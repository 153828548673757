import {
  createAsyncThunk,
} from "@reduxjs/toolkit";
import {
  createContractApi,
  createContractEmailApi,
  isAxiosErrorProblemDetails,
} from "../../api";
import {
  ProblemDetails,
  ContractDetailsViewModel,
} from "../../api/axcloud";

export interface ContactsState {
  email1?: string;
  email2?: string | null;
  email3?: string | null;
}

interface UpdateEmailArgType {
  accessToken: string;
  email: string;
}

/**
 * メールアドレスを変更します。
 */
export const updateEmail = createAsyncThunk<
  ContractDetailsViewModel,
  UpdateEmailArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "contacts/updateEmail",
  async (arg, thunk) => {
    try {
      const api = createContractApi(arg.accessToken);
      const res = await api.patchContract([
        {
          op: "replace",
          path: "/email",
          value: arg.email,
        }
      ]);
      return res.data;
    } catch (error) {
      if (!isAxiosErrorProblemDetails(error))
      {
        throw error;
      }
      return thunk.rejectWithValue(error.response!.data);
    }
  }
);

/**
 * メールアドレス(予備1)を変更します。
 */
export const updateSubEmail1 = createAsyncThunk<
  ContractDetailsViewModel,
  UpdateEmailArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "contacts/updateSubEmail1",
  async (arg, thunk) => {
    try {
      const api = createContractApi(arg.accessToken);
      const res = await api.patchContract([
        {
          op: "replace",
          path: "/subEmail1",
          value: arg.email,
        }
      ]);
      return res.data;
    } catch (error) {
      if (!isAxiosErrorProblemDetails(error))
      {
        throw error;
      }
      return thunk.rejectWithValue(error.response!.data);
    }
  }
);

/**
 * メールアドレス(予備2)を変更します。
 */
export const updateSubEmail2 = createAsyncThunk<
  ContractDetailsViewModel,
  UpdateEmailArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "contacts/updateSubEmail2",
  async (arg, thunk) => {
    try {
      const api = createContractApi(arg.accessToken);
      const res = await api.patchContract([
        {
          op: "replace",
          path: "/subEmail2",
          value: arg.email,
        }
      ]);
      return res.data;
    } catch (error) {
      if (!isAxiosErrorProblemDetails(error))
      {
        throw error;
      }
      return thunk.rejectWithValue(error.response!.data);
    }
  }
);

interface DeleteEmailArgType {
  accessToken: string;
}

/**
 * メールアドレス(予備1)を削除します。
 */
export const deleteSubEmail1 = createAsyncThunk<
  ContractDetailsViewModel,
  DeleteEmailArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "contacts/deleteSubEmail1",
  async (arg, thunk) => {
    try {
      const api = createContractApi(arg.accessToken);
      const res = await api.patchContract([
        {
          op: "remove",
          path: "/subEmail1",
        }
      ]);
      return res.data;
    } catch (error) {
      if (!isAxiosErrorProblemDetails(error))
      {
        throw error;
      }
      return thunk.rejectWithValue(error.response!.data);
    }
  }
);

/**
 * メールアドレス(予備2)を削除します。
 */
export const deleteSubEmail2 = createAsyncThunk<
  ContractDetailsViewModel,
  DeleteEmailArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "contacts/deleteSubEmail2",
  async (arg, thunk) => {
    try {
      const api = createContractApi(arg.accessToken);
      const res = await api.patchContract([
        {
          op: "remove",
          path: "/subEmail2",
        }
      ]);
      return res.data;
    } catch (error) {
      if (!isAxiosErrorProblemDetails(error))
      {
        throw error;
      }
      return thunk.rejectWithValue(error.response!.data);
    }
  }
);

export const testEmail = createAsyncThunk<
  void,
  UpdateEmailArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "contacts/testEmail",
  async (arg, thunk) => {
    try {
      const api = createContractEmailApi(arg.accessToken);
      const res = await api.test({
        email: arg.email,
      });
      return res.data;
    } catch (error) {
      if (!isAxiosErrorProblemDetails(error))
      {
        throw error;
      }
      return thunk.rejectWithValue(error.response!.data);
    }
  }
);
