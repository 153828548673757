import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  ContractProductViewModel,
  ContractProductsApi,
  ProblemDetails,
} from '../../api/axcloud';
import {
  createConfiguration,
  isAxiosErrorProblemDetails,
} from '../../api';
import {
  logout,
} from "../login/loginSlice";

type ListContractProductsArgType = {
  accessToken: string;
};

type ListContractProductsReturnType = {
  products: ContractProductViewModel[],
};

export const listContractProducts = createAsyncThunk<ListContractProductsReturnType, ListContractProductsArgType>(
  "products/listContractProducts",
  async (arg, thunk): Promise<ListContractProductsReturnType> => {
    const config = createConfiguration(arg.accessToken);
    const api = new ContractProductsApi(config);
    const res = await api.listContractProducts();
    return {
      products: res.data
    };
  }
);

type UpgradeContractProductArgType = {
  accessToken: string;
  productId: string;
  version: string;
};

export const upgradeContractProduct = createAsyncThunk<
  void,
  UpgradeContractProductArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "products/upgradeContractProduct",
  async (arg, thunk) => {
    try {
      const config = createConfiguration(arg.accessToken);
      const api = new ContractProductsApi(config);
      const res = await api.upgradeContractProduct(arg.productId, {
        version: arg.version,
      });
      return res.data;
    } catch (error) {
      if (isAxiosErrorProblemDetails(error)) {
        return thunk.rejectWithValue(error.response!.data);
      } else {
        throw error;
      }
    }
  }
);

export interface ProductsState {
  products: ContractProductViewModel[];
  upgradingProduct: ContractProductViewModel | null;
}

const initialState: ProductsState = {
  products: [],
  upgradingProduct: null,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    openUpgrade: (state, action: PayloadAction<ContractProductViewModel>) => ({
      ...state,
      upgradingProduct: action.payload,
    }),
    cancelUpgrade: state => ({
      ...state,
      upgradingProduct: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(listContractProducts.fulfilled, (state, action) => {
      return {
        ...state,
        products: action.payload.products,
      };
    });
    builder.addCase(upgradeContractProduct.fulfilled, (state, action) => {
      return {
        ...state,
        upgradingProduct: null,
      };
    });
    builder.addCase(logout.fulfilled, (state, action) => ({
      ...initialState,
    }));
  },
});

export const {
  openUpgrade,
  cancelUpgrade,
} = productsSlice.actions;

export default productsSlice.reducer;
