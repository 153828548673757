import React from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  PictureAsPdf,
} from '@material-ui/icons';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  ContractDetailsViewModel,
} from "../../api/axcloud";
import {
  formatDate,
} from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      marginBottom: 50,
    },
    tableCell: {
      width: "30%",
    },
  }),
);

export interface ContractTemplateProps {
  contract: ContractDetailsViewModel | null;
}

export function ContractTemplate({
  contract
}: ContractTemplateProps) {
  const classes = useStyles({});

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          契約内容
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>
                契約 ID
              </TableCell>
              <TableCell>
                {contract && contract.contractId}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                利用期間
              </TableCell>
              <TableCell>
                {formatDate(contract?.startAt)} ～  {formatDate(contract?.endAt)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                利用ユーザー
              </TableCell>
              <TableCell>
                {contract?.platform?.name}
              </TableCell>
            </TableRow>

            {contract && !!contract.additionalDataCapacity && (
              <TableRow>
                <TableCell>
                  追加データ容量
                </TableCell>
                <TableCell>
                  {contract.additionalDataCapacity} GB
                </TableCell>
              </TableRow>
            )}

            {contract && !!contract.additionalDataAreaCount && (
              <TableRow>
                <TableCell>
                  追加データ領域数
                </TableCell>
                <TableCell>
                  {contract.additionalDataAreaCount} データ領域
                </TableCell>
              </TableRow>
            )}

            {contract && contract.products && contract.products.map(x => (
              <>
                <TableRow key={x.productId}>
                  <TableCell>
                    {x.productName}
                  </TableCell>
                  <TableCell>
                    {x.licenseCount}ライセンス、
                    シリアル番号：{x.serialNumber}、
                    DMSS番号：{x.dmssNumber}
                  </TableCell>
                </TableRow>
                {x.options && x.options.map(o => (
                  <TableRow key={o.optionId}>
                    <TableCell>
                      <span
                        style={{
                          paddingLeft: "1em",
                        }}
                      >
                        {o.optionName}
                      </span>
                    </TableCell>
                    <TableCell>
                      {
                        o.licenseCount &&
                        o.licenseCount > 0 &&
                        `${o.licenseCount}ライセンス`
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ))}

            {contract?.authenticationCode && (
              <TableRow>
                <TableCell>
                  認証コード
                </TableCell>
                <TableCell>
                  {contract.authenticationCode}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell>約款</TableCell>
              <TableCell>
                <PictureAsPdf
                  style={{
                    color: "red",
                  }}
                />
                <a
                  href="https://www.ohken.co.jp/cloud/docs/ax_cloud_notes.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  サービス約款
                </a>
                <p>
                  ※お客様と販売代理店様との間で本約款と異なる内容で合意した場合、その内容が本約款に優先して適用されます。
                </p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Paper className={classes.root}>
        <Typography variant="subtitle1">
          大臣AXクラウドの操作に関するお問い合わせ
        </Typography>
        <Typography variant="body1">
          <p>カスタマーサポートセンターまでご連絡をお願いいたします。</p>
          <p
            style={{
              marginLeft: "1em",
            }}
          >
            フリーダイヤル　0120-999-070<br/>
            受付：9～12時／13～17時（土日祝および弊社休業日を除く）
          </p>
          <p>
            ※お問い合わせの際には、シリアル番号が必要です。（契約内容枠内に表示されています）<br/>
          </p>
          <p>
            ※無償利用期間中はカスタマーサポートセンター/DMSS会員様専用サイトのご利用はできません。
          </p>
        </Typography>
      </Paper>
    </>
  );
}
