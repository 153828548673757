import React, {
  useCallback,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

export interface TestEmailDialogValues {
  email: string;
}

export interface TestEmailDialogProps {
  open: boolean;
  email?: string | null;
  onSubmit: (values: TestEmailDialogValues) => void;
  onClose: () => void;
}

/** メール送信テストダイアログ */
export const TestEmailDialog: React.VFC<TestEmailDialogProps> = ({
  open,
  email,
  onSubmit,
  onClose,
}) => {
  const handleSubmit = useCallback(() => {
    if (email) {
      onSubmit({
        email,
      });
    }
  }, [onSubmit, email]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        メールのテスト送信
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          「{email}」宛にメールをテスト送信しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="default"
        >
          いいえ
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={!email}
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};
