import React from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import {
  AccessKeyViewModel,
} from '../../api/axcloud';
import {
  AccessKeyDialog,
} from "./AccessKeyDialog";
import {
  NewAccessKeyDialog,
} from './NewAccessKeyDialog';
import {
  DeleteAccessKeyDialog,
} from './DeleteAccessKeyDialog';
import {
  EditAccessKeyDialog,
} from './EditAccessKeyDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    actionCell: {
      width: 300,
    }
  }),
);

export interface AdvancedAccessKeysTemplateProps {
  accessKeys: AccessKeyViewModel[];

  openNew: boolean;
  onOpenNew: () => void;
  onCancelNew: () => void;
  onSubmitNew: (name: string) => void;

  editingAccessKey: AccessKeyViewModel | null;
  onOpenEdit: (accessKey: AccessKeyViewModel) => void;
  onCancelEdit: () => void;
  onSubmitEdit: (accessKey: AccessKeyViewModel, name: string) => void;

  deletingAccessKey: AccessKeyViewModel | null;
  onOpenDelete: (accessKey: AccessKeyViewModel) => void;
  onCancelDelete: () => void;
  onSubmitDelete: (accessKey: AccessKeyViewModel) => void;

  onCopy: (accessKey: AccessKeyViewModel) => void;

  showingAccessKey?: AccessKeyViewModel | null;
  onOpenShow?: (accessKey: AccessKeyViewModel) => void;
  onCancelShow?: () => void;
}

export function AdvancedAccessKeysTemplate(
  props: AdvancedAccessKeysTemplateProps
) {
  const classes = useStyles({});

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
            アクセスキー
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell className={classes.actionCell}>
                <Button variant="contained" onClick={props.onOpenNew}>
                  新規作成
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.accessKeys.map(x => (
              <TableRow key={x.id!}>
                <TableCell>
                  {x.name}
                </TableCell>
                <TableCell>
                  <ButtonGroup>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => props.onCopy(x)}
                    >
                      コピー
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        props.onOpenShow && props.onOpenShow(x);
                      }}
                    >
                      表示
                    </Button>
                    <Button
                      variant="contained"
                      disabled={x.isPrimary}
                      onClick={() => {
                        props.onOpenEdit(x);
                      }}
                    >
                      編集
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={x.isPrimary}
                      onClick={() => {
                        props.onOpenDelete(x);
                      }}
                    >
                      削除
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <AccessKeyDialog
        showingAccessKey={props.showingAccessKey}
        onClose={props.onCancelShow}
      />
      <NewAccessKeyDialog
        open={props.openNew}
        onCancel={props.onCancelNew}
        onSubmit={props.onSubmitNew}
      />
      <EditAccessKeyDialog
        editingAccessKey={props.editingAccessKey}
        onCancel={props.onCancelEdit}
        onSubmit={props.onSubmitEdit}
      />
      <DeleteAccessKeyDialog
        deletingAccessKey={props.deletingAccessKey}
        onSubmit={props.onSubmitDelete}
        onCancel={props.onCancelDelete}
      />
    </>
  );
}
