import React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  AccessKeyViewModel,
} from "../../api/axcloud";

export interface AccessKeyDialogProps {
  showingAccessKey?: AccessKeyViewModel | null;
  onClose?: () => void;
}

export const AccessKeyDialog: React.FC<AccessKeyDialogProps> = ({
  showingAccessKey,
  onClose,
}) => {
  return (
    <Dialog
      open={!!showingAccessKey}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {showingAccessKey?.name}
      </DialogTitle>
      <DialogContent>
        <TextField
          multiline
          variant="filled"
          fullWidth
          defaultValue={showingAccessKey?.value}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            readOnly: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};