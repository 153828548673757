import React, {
  useCallback,
  useEffect,
} from "react";
import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  createStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  useTypedSelector,
  useAppDispatch,
} from "../../app/store";
import {
  clearPasswordPolicy,
  resolvePasswordPolicy,
} from "./securitySlice";
import { isValidDaijinPassword } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginTop: 10,
    },
  }),
);

export interface ResetPasswordDialogValues {
  newPassword: string;
  newPasswordConfirmation: string;
}

export interface ResetPasswordDialogProps {
  open: boolean;
  onSubmit: (values: ResetPasswordDialogValues) => Promise<void>;
  onClose: () => void;
}

export const ResetPasswordDialog: React.VFC<ResetPasswordDialogProps> = ({
  open,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const state = useTypedSelector(x => ({
    login: x.login,
    security: x.security,
  }));

  const validatePassword = useCallback((value: string) => {
    const minLength = state.security.passwordPolicy?.minLength ?? 8;
    const requireComplexity = state.security.passwordPolicy?.requireComplexity ?? true;
    return isValidDaijinPassword(value, minLength, requireComplexity);
  }, [state.security.passwordPolicy]);

  useEffect(() => {
    if (open && state.login.accessToken) {
      dispatch(resolvePasswordPolicy({
        accessToken: state.login.accessToken,
      }));
    } else {
      dispatch(clearPasswordPolicy());
    }
  }, [dispatch, open, state.login.accessToken]);

  const {
    handleSubmit,
    watch,
    reset,
    control,
    formState: {
      isValid,
      isSubmitting,
      errors,
    },
  } = useForm<ResetPasswordDialogValues>({
    mode: "all",
  });
  const {
		newPassword,
  } = watch();

  useEffect(() => {
    // ダイアログを表示したタイミングでフォームをリセットする
    open && reset();
  }, [reset, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
				パスワードのリセット
      </DialogTitle>
      <DialogContent>
        <form
					id="resetPasswordForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: "新しいパスワードは必須です。",
              validate: validatePassword,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="新しいパスワード"
								type="password"
                className={classes.input}
                autoFocus
                fullWidth
                helperText={errors.newPassword?.message}
                error={!!errors.newPassword}
              />
            )}
          />
          <Controller
            name="newPasswordConfirmation"
            control={control}
            rules={{
              required: "新しいパスワード(確認用)は必須です。",
              validate: (value) => {
                return newPassword === value ?
                  true :
                  "新しいパスワードと新しいパスワード(確認用)が一致していません。";
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="新しいパスワード(確認用)"
                type="password"
                className={classes.input}
                fullWidth
                helperText={errors.newPasswordConfirmation?.message}
                error={!!errors.newPasswordConfirmation}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="default"
          disabled={isSubmitting}
        >
          キャンセル
        </Button>
        <Button
          color="secondary"
          type="submit"
					form="resetPasswordForm"
          disabled={
            !state.security.passwordPolicy ||
            !isValid ||
            isSubmitting
          }
        >
          リセット
        </Button>
      </DialogActions>
    </Dialog>
  );
};
