import React, {
  useEffect,
} from 'react';
import {
  LogsTemplate,
} from './LogsTemplate';
import {
  useTypedSelector,
  useAppDispatch,
} from '../../app/store';
import {
  listContractLogs,
} from './contractLogsSlice';

export function LogsPage() {
  const state = useTypedSelector(x => ({
    ...x.contractLogs,
    ...x.login,
  }));
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(listContractLogs({
      accessToken: state.accessToken!,
      page: 0,
      perPage: state.perPage,
    }));
  }, [dispatch, state.accessToken, state.perPage]);

  return (
    <LogsTemplate
      logs={state.logs}
      count={state.totalCount}
      page={state.page}
      rowsPerPage={state.perPage}
      onChangePage={(page) => {
        dispatch(listContractLogs({
          accessToken: state.accessToken!,
          page: page,
          perPage: state.perPage,
        }));
      }}
    />
  );
}
