import React from 'react';
import {
  useTypedSelector,
  useAppDispatch,
} from '../../app/store';
import {
  hideError,
  hideSuccess,
} from './alertsSlice';
import {
  AlertSnackbar,
} from './AlertSnackbar';

export function Alerts() {
  const state = useTypedSelector(x => x.alerts);
  const dispatch = useAppDispatch();

  return (
    <>
      <AlertSnackbar
        open={state.showSuccess}
        severity="success"
        message={state.successMessage}
        autoHideDuration={5000}
        onClose={() => dispatch(hideSuccess())}
     />
      <AlertSnackbar
        open={state.showError}
        message={state.errorMessage}
        severity="error"
        onClose={() => dispatch(hideError())}
     />
    </>
  );
}