import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  ContractDetailsViewModel,
} from "../../api/axcloud";
import {
  createContractApi,
} from "../../api";
import {
  updateEmail,
  updateSubEmail1,
  updateSubEmail2,
  deleteSubEmail1,
  deleteSubEmail2,
} from "../contacts/contactsSlice";
import {
  logout,
} from "../login/loginSlice";

type GetContractDetailsArgType = {
  accessToken: string;
};

export const getContractDetails = createAsyncThunk<
  ContractDetailsViewModel,
  GetContractDetailsArgType
>(
  "contract/getContractDetails",
  async (arg, thunk): Promise<ContractDetailsViewModel> => {
    const api = createContractApi(arg.accessToken);
    const res = await api.getContractDetails();
    return res.data;
  }
);

export interface ContractState {
  details: ContractDetailsViewModel | null;
}

const initialState: ContractState = {
  details: null,
};

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getContractDetails.fulfilled, (state, action) => ({
      ...state,
      details: action.payload,
    }));
    builder.addCase(updateEmail.fulfilled, (state, action) => ({
      ...state,
      details: action.payload,
    }));
    builder.addCase(updateSubEmail1.fulfilled, (state, action) => ({
      ...state,
      details: action.payload,
    }));
    builder.addCase(updateSubEmail2.fulfilled, (state, action) => ({
      ...state,
      details: action.payload,
    }));
    builder.addCase(deleteSubEmail1.fulfilled, (state, action) => ({
      ...state,
      details: action.payload,
    }));
    builder.addCase(deleteSubEmail2.fulfilled, (state, action) => ({
      ...state,
      details: action.payload,
    }));
    builder.addCase(logout.fulfilled, (state, action) => ({
      ...initialState,
    }));
  },
});

export default contractSlice.reducer;
