import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  createContractApi,
  createContractLoginApi,
  isAxiosErrorProblemDetails,
} from '../../api';
import {
  TokenViewModel,
  ProblemDetails,
} from '../../api/axcloud';

interface ContractLoginArgType {
  contractId: string;
  password: string;
}

export const contractLogin = createAsyncThunk<
  TokenViewModel,
  ContractLoginArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "login/contractLogin",
  async (arg, thunk) => {
    const api = createContractLoginApi();
    try {
      const res = await api.contractLogin({
        contractId: arg.contractId,
        password: arg.password,
      });

      // sessionStore に保存し、
      // ブラウザをリロードしても自動でログインできるようにする。
      const json = JSON.stringify(res.data);
      sessionStorage.setItem(ACCESS_TOKEN_KEY, json);

      return res.data;
    } catch (error) {
      if (isAxiosErrorProblemDetails(error)) {
        return thunk.rejectWithValue(error.response!.data);
      } else {
        throw error;
      }
    }
  }
);

const ACCESS_TOKEN_KEY = "login/ACCESS_TOKEN";

export const autoLogin = createAsyncThunk<
  TokenViewModel,
  void,
  {
    rejectValue: ProblemDetails
  }
>(
  "login/autoLogin",
  async (arg, thunk) => {
    const item = sessionStorage.getItem(ACCESS_TOKEN_KEY);
    if (item) {
      const token: TokenViewModel = JSON.parse(item);
      return token;
    }
    const details: ProblemDetails = {
      detail: "アクセストークンが保存されていません。",
    };
    return thunk.rejectWithValue(details);
  }
);

interface ChangeContractPasswordArgType {
  accessToken: string;
  currentPassword: string;
  newPassword: string;
}

export const changeContractPassword = createAsyncThunk<
  void,
  ChangeContractPasswordArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "login/changeContractPassword",
  async (arg, thunk) => {
    try {
      const api = createContractApi(arg.accessToken);
      await api.changeContractPassword({
        currentPassword: arg.currentPassword,
        newPassword: arg.newPassword,
      });
    } catch (error) {
      if (isAxiosErrorProblemDetails(error)) {
        return thunk.rejectWithValue(error.response!.data);
      } else {
        throw error;
      }
    }
  }
);

interface ResetContractPasswordArgType {
  contractId: string;
  passwordResetToken: string;
  newPassword: string;
}

export const resetContractPassword = createAsyncThunk<
  void,
  ResetContractPasswordArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "login/resetContractPassword",
  async (arg, thunk) => {
    try {
      const api = createContractApi();
      await api.resetContractPassword({
        contractId: arg.contractId,
        token: arg.passwordResetToken,
        newPassword: arg.newPassword,
      });
    } catch (error) {
      if (isAxiosErrorProblemDetails(error)) {
        return thunk.rejectWithValue(error.response!.data);
      } else {
        throw error;
      }
    }
  }
);

interface ForgotContractIdArgType {
  email: string;
}

export const forgotContractId = createAsyncThunk<
  void,
  ForgotContractIdArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "login/forgotContractId",
  async (arg, thunk) => {
    try {
      const api = createContractApi();
      await api.forgotContractId({
        email: arg.email,
      });
    } catch (error) {
      if (isAxiosErrorProblemDetails(error)) {
        return thunk.rejectWithValue(error.response!.data);
      } else {
        throw error;
      }
    }
  }
);

interface ForgotPasswordArgType {
  contractId: string;
  email: string;
}

export const forgotPassword = createAsyncThunk<
  void,
  ForgotPasswordArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "login/forgotPassword",
  async (arg, thunk) => {
    try {
      const api = createContractApi();
      await api.forgotContractPassword({
        contractId: arg.contractId,
        email: arg.email,
      });
    } catch (error) {
      if (isAxiosErrorProblemDetails(error)) {
        return thunk.rejectWithValue(error.response!.data);
      } else {
        throw error;
      }
    }
  }
);

export const logout = createAsyncThunk<
  void,
  void
>(
  "login/logout",
  (arg, thunk) => {
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  }
);

export interface LoginState {
  /** ログインしているかどうか */
  loggedIn: boolean;
  /** アクセストークン */
  accessToken?: string | null;
  /** 契約 ID */
  contractId?: string | null;
  /** 会社名 */
  companyName?: string | null;
  /** パスワード変更が必要かどうか*/
  requiresPasswordChange?: boolean | null;
}

const initialState: LoginState = {
  loggedIn: false,
  accessToken: null,
  contractId: null,
  companyName: null,
  requiresPasswordChange: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state, action) => {
      return {
        ...state,
      loggedIn: false,
      accessToken: null,
      contractId: null,
      companyName: null,
      requiresPasswordChange: null,
      };
    });

    builder.addCase(contractLogin.pending, (state, action) => {
      return {
        ...state,
        loggingIn: true,
      };
    });
    builder.addCase(contractLogin.rejected, (state, action) => {
      return {
        ...state,
        loggingIn: false,
      };
    });
    builder.addCase(contractLogin.fulfilled, (state, action) => {
      return {
        ...state,
        loggedIn: true,
        accessToken: action.payload.accessToken,
        contractId: action.payload.contract?.id,
        companyName: action.payload.contract?.companyName,
        requiresPasswordChange: action.payload.contract?.requiresPasswordChange,
      };
    });

    builder.addCase(changeContractPassword.fulfilled, (state, action) => {
      return {
        ...state,
        requiresPasswordChange: false,
      };
    });

    builder.addCase(autoLogin.pending, (state, action)=> {
      return {
        ...state,
        loggingIn: true,
      };
    });
    builder.addCase(autoLogin.rejected, (state, action) => {
      return {
        ...state,
        loggingIn: false,
      };
    });
    builder.addCase(autoLogin.fulfilled, (state, action) => {
      return {
        ...state,
        loggedIn: true,
        accessToken: action.payload.accessToken,
        contractId: action.payload.contract?.id,
        companyName: action.payload.contract?.companyName,
        requiresPasswordChange: action.payload.contract?.requiresPasswordChange,
      };
    });
  },
});

export default loginSlice.reducer;
