import { combineReducers } from '@reduxjs/toolkit';
import contractReducer from "../features/contract/contractSlice";
import accessKeysReducer from "../features/accessKeys/accessKeysSlice";
import contractLogsReducer from "../features/contractLogs/contractLogsSlice";
import loginReducer from "../features/login/loginSlice";
import productsReducer from "../features/products/productsSlice";
import securityReducer from "../features/security/securitySlice";
import alertsReducer from "../features/alerts/alertsSlice";

const rootReducer = combineReducers({
  login: loginReducer,
  contract: contractReducer,
  accessKeys: accessKeysReducer,
  products: productsReducer,
  contractLogs: contractLogsReducer,
  security: securityReducer,
  alerts: alertsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
