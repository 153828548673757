import React, {
  useCallback,
} from 'react';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  isValidPassword,
} from "../../utils";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export interface InitialPasswordFormValues {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export interface InitialPasswordFormProps {
  className?: string;
  onSubmit?: (values: InitialPasswordFormValues) => void;
}

export const InitialPasswordForm: React.VFC<InitialPasswordFormProps> = ({
  className,
  onSubmit,
}) => {
  const classes = useStyles();
  const {
    handleSubmit,
    watch,
    control,
    formState: {
      errors,
      isValid,
    },
  } = useForm<InitialPasswordFormValues>({
    mode: "onChange",
  });
  const {
    newPassword,
  } = watch();
  const onValid = useCallback((values: InitialPasswordFormValues) => {
    onSubmit && onSubmit(values);
  }, [onSubmit]);

  return (
    <form
      className={className}
      noValidate
      onSubmit={handleSubmit(onValid)}
    >
      <Controller
        control={control}
        name="currentPassword"
        rules={{
          required: "現在のパスワードは必須です。",
        }}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            fullWidth
            label="現在のパスワード"
            type="password"
            error={!!errors.currentPassword}
            helperText={errors.currentPassword?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="newPassword"
        rules={{
          required: "新しいパスワードは必須です。",
          validate: x => isValidPassword(x) ?
            true :
            "新しいパスワードは半角英小文字大文字数字記号をそれぞれ1種類以上含む8文字以上である必要があります。",
        }}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            fullWidth
            label="新しいパスワード"
            type="password"
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="newPasswordConfirmation"
        rules={{
          required: "新しいパスワード(確認)は必須です。",
          validate: (value: string) => {
            return value === newPassword ? true : "新しいパスワードと一致しません。";
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            fullWidth
            label="新しいパスワード(確認)"
            type="password"
            error={!!errors.newPasswordConfirmation}
            helperText={errors.newPasswordConfirmation?.message}
          />
        )}
      />
      <p>
        ※変更後のパスワードは厳重な管理をお願いいたします。
      </p>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!isValid}
      >
        OK
      </Button>
    </form>
  );
};
