import React, {
  useCallback,
} from 'react';
import {
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  isValidContractId,
} from "../../utils";

export interface LoginFormProps {
  onLogin?: (contractId: string, password: string) => Promise<void>;
}

export interface LoginFormValues {
  contractId: string;
  password: string;
}

export function LoginForm({
  onLogin,
}: LoginFormProps) {
  const {
    handleSubmit,
    control,
    formState: {
      errors,
      isValid,
      isSubmitting,
    },
  } = useForm<LoginFormValues>({
    mode: "all",
  });
  const onValid = useCallback(async (values: LoginFormValues) => {
    if (onLogin) {
      await onLogin(values.contractId, values.password);
    }
  }, [onLogin]);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onValid)}
    >
      <Controller
        control={control}
        name="contractId"
        rules={{
          required: "契約IDは必須です。",
          validate: value => isValidContractId(value) ?
            true :
            "有効な契約IDではありません",
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label="契約ID"
            style={{ marginTop: "30px"}}
            error={!!errors.contractId}
            helperText={errors.contractId?.message}
          />
        )}
      />
      <div />

      <Controller
        control={control}
        name="password"
        rules={{
          required: "パスワードは必須です。"
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label="パスワード"
            type="password"
            style={{ marginTop: "30px"}}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        )}
      />
      <div />

      <div
        style={{
          margin: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            ログイン
          </Button>
          {
            isSubmitting &&
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          }
        </div>
      </div>
    </form>
  );
}
