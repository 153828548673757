import React, {
  useEffect,
  useCallback,
} from 'react';
import {
  AccessKeyTemplate,
} from './AccessKeyTemplate';
import {
  useAppDispatch,
  useTypedSelector,
} from "../../app/store";
import {
  regenerateContractPrimaryAccessKey,
  getContractPrimaryAccessKey,
  copyContractAccessKey,
  openRegenerate,
  cancelRegenerate,
} from "./accessKeysSlice";

export const AccessKeyPage: React.FC = () => {
  const state = useTypedSelector(x => ({
    ...x.accessKeys,
    ...x.login,
  }));
  const dispatch = useAppDispatch();
  const handleCopy = useCallback(() => {
    if (state.defaultAccessKey) {
      dispatch(copyContractAccessKey({
        accessKey: state.defaultAccessKey,
      }));
    }
  }, [dispatch, state.defaultAccessKey]);
  const handleOpenRegenerate = useCallback(() => {
    dispatch(openRegenerate());
  }, [dispatch]);
  const handleCancelRegenerate = useCallback(() => {
    dispatch(cancelRegenerate());
  }, [dispatch]);
  const handleSubmitRegenerate = useCallback(() => {
    dispatch(regenerateContractPrimaryAccessKey({
      accessToken: state.accessToken!,
    }));
  }, [dispatch, state.accessToken]);
  useEffect(() => {
    dispatch(getContractPrimaryAccessKey({
      accessToken: state.accessToken!,
    }));
  }, [dispatch, state.accessToken]);

  return (
    <AccessKeyTemplate
      companyName={state.companyName}
      defaultAccessKey={state.defaultAccessKey}
      openRegenerate={state.openRegenerate}
      onCopy={handleCopy}
      onOpenRegenerate={handleOpenRegenerate}
      onSubmitRegenerate={handleSubmitRegenerate}
      onCancelRegenerate={handleCancelRegenerate}
    />
  );
};
