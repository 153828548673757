import {
	useEffect,
} from "react";
import {
	useAppDispatch,
} from "../../app/store";
import {
	autoLogin,
} from "./loginSlice";

export function useAutoLogin() {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(autoLogin());
	}, [dispatch]);
}
