import React, {
  useCallback,
} from "react";
import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  useHistory,
  Link,
} from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
} from '@material-ui/core';
import {
  LockOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  useAppDispatch,
} from "../../app/store";
import {
  resetContractPassword,
} from "./loginSlice";
import {
  isProblemDetails,
} from "../../api";
import {
  isValidContractId,
  isValidPassword,
} from "../../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface FormValues {
  contractId: string;
  newPassword: string;
  newPasswordConfirmation: string;
  passwordResetToken: string;
}

export const ResetPasswordPage: React.FC = () => {
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    watch,
    formState,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  const {
    newPassword,
  } = watch();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onSubmit = useCallback(async (values: FormValues) => {
    const res = await dispatch(resetContractPassword({
      contractId: values.contractId,
      newPassword: values.newPassword,
      passwordResetToken: values.passwordResetToken,
    }));
    if (!isProblemDetails(res.payload)) {
      history.push("/");
    }
  }, [dispatch, history]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          パスワードリセット
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="contractId"
            rules={{
              required: "契約IDは必須です。",
              validate: x => isValidContractId(x) ?
                true :
                "有効な契約IDではありません。",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="契約ID"
                id="contractId"
                autoComplete="contract-id"
                error={!!formState.errors.contractId}
                helperText={formState.errors.contractId?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="passwordResetToken"
            rules={{
              required: "パスワードリセットトークンは必須です。",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="パスワードリセットトークン"
                id="passwordResetToken"
                autoComplete="password-reset-token"
                error={!!formState.errors.passwordResetToken}
                helperText={formState.errors.passwordResetToken?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="newPassword"
            rules={{
              required: "新しいパスワードは必須です。",
              validate: x => isValidPassword(x) ?
                true :
                "新しいパスワードは半角英小文字大文字数字記号をそれぞれ1種類以上含む8文字以上である必要があります。",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="新しいパスワード"
                type="password"
                id="newPassword"
                autoComplete="new-password"
                error={!!formState.errors.newPassword}
                helperText={formState.errors.newPassword?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="newPasswordConfirmation"
            rules={{
              required: "新しいパスワード(確認)は必須です。",
              validate: x => x === newPassword ?
                true :
                "新しいパスワードと一致していません。",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="新しいパスワード(確認)"
                type="password"
                id="newPasswordConfirmation"
                autoComplete="new-password-confirmation"
                error={!!formState.errors.newPasswordConfirmation}
                helperText={formState.errors.newPasswordConfirmation?.message}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formState.isValid || formState.isSubmitting}
          >
            OK
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="default"
            disabled={formState.isSubmitting}
            component={Link}
            to="/"
          >
            キャンセル
          </Button>
        </form>
      </div>
    </Container>
  );
};
