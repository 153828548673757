import React, {
  useState,
  useCallback,
} from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export interface NewAccessKeyDialogProps {
  open: boolean;
  onCancel: () => void;
  onSubmit: (name: string) => void;
}

export function NewAccessKeyDialog({
  open,
  onCancel,
  onSubmit,
}: NewAccessKeyDialogProps) {
  const [name, setName] = useState("");
  const handleCancel = useCallback(() => {
    onCancel();
    setName("");
  }, [onCancel]);
  const handleSubmit = useCallback(() => {
    onSubmit(name);
    setName("");
  }, [name, onSubmit]);

  return (
    <Dialog
      open={open}
      onClose={handleCancel}>
      <DialogTitle>新しいアクセスキー</DialogTitle>
      <DialogContent>
        <DialogContentText>
        </DialogContentText>
        <TextField
          label="名前"
          type="text"
          required
          margin="dense"
          fullWidth
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!name || name.length === 0}
        >
          作成
        </Button>
        <Button onClick={handleCancel}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
}
