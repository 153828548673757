import React, {
  useEffect,
} from 'react';
import {
  useTypedSelector,
  useAppDispatch,
} from '../../app/store';
import {
  listContractAccessKeys,
  registerContractAccessKey,
  updateContractAccessKey,
  deleteContractAccessKey,
  copyContractAccessKey,
  openNew,
  openEdit,
  openDelete,
  cancelDelete,
  cancelEdit,
  cancelNew,
  openShow,
  cancelShow,
} from './accessKeysSlice';
import {
  AdvancedAccessKeysTemplate,
} from './AdvancedAccessKeysTemplate';

export function AdvancedAccessKeysPage() {
  const dispatch = useAppDispatch();
  const state = useTypedSelector(x => ({
    ...x.accessKeys,
    ...x.login,
  }));
  useEffect(() => {
    dispatch(listContractAccessKeys({
      accessToken: state.accessToken!,
    }));
  }, [dispatch, state.accessToken]);

  return (
    <AdvancedAccessKeysTemplate
      accessKeys={state.accessKeys}
      openNew={state.openNew}
      editingAccessKey={state.editingAccessKey}
      deletingAccessKey={state.deletingAccessKey}
      showingAccessKey={state.showingAccessKey}
      onOpenNew={() => dispatch(openNew())}
      onOpenEdit={x => dispatch(openEdit(x))}
      onOpenDelete={x => dispatch(openDelete(x))}
      onOpenShow={x => dispatch(openShow(x))}
      onCancelShow={() => dispatch(cancelShow())}
      onCancelNew={() => dispatch(cancelNew())}
      onCancelEdit={() => dispatch(cancelEdit())}
      onCancelDelete={() => dispatch(cancelDelete())}
      onSubmitNew={name => {
        dispatch(registerContractAccessKey({
          accessToken: state.accessToken!,
          name,
        }));
      }}
      onSubmitEdit={(x, name) => {
        dispatch(updateContractAccessKey({
          accessToken: state.accessToken!,
          id: x.id!,
          name,
        }));
      }}
      onSubmitDelete={x => {
        dispatch(deleteContractAccessKey({
          accessToken: state.accessToken!,
          id: x.id!,
        }));
      }}
      onCopy={x => {
        dispatch(copyContractAccessKey({
          accessKey: x,
        }));
      }}
    />
  );
}
