import React from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  ContractLogViewModel,
} from '../../api/axcloud';
import {
  formatDateTime,
} from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    tableCell: {
      width: "30%",
    },
  }),
);

export interface LogsTemplateProps {
  page: number;
  count: number;
  rowsPerPage: number;
  logs: ContractLogViewModel[];
  onChangePage: (page: number) => void;
}

export function LogsTemplate(props: LogsTemplateProps) {
  const classes = useStyles({});

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" component="h3">
        操作ログ
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>日時</TableCell>
            <TableCell>アクセス元 IP</TableCell>
            <TableCell>操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.logs.map(x => (
            <TableRow key={x.id!}>
              <TableCell>
                {formatDateTime(x.loggedAt)}
              </TableCell>
              <TableCell>
                {x.ipAddress}
              </TableCell>
              <TableCell>
                {x.action}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPage={props.rowsPerPage}
        count={props.count}
        page={props.page}
        onChangePage={(e, page) => props.onChangePage(page)}
        onChangeRowsPerPage={() => {}}
        />
    </Paper>
  );
}
