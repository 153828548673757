import React from "react";
import {
  Link,
} from "react-router-dom";
import {
  Button,
  Paper,
} from "@material-ui/core";
import {
  LoginForm,
} from "./LoginForm";
import "./LoginTemplate.css";

export interface LoginTemplateProps {
  onLogin?: (contractId: string, password: string) => Promise<void>;
}

export function LoginTemplate(props: LoginTemplateProps) {
  return (
    <div className="app">
      <div className="banner">
        <Paper className="login">
          <div className="accent"/>
          <LoginForm
            onLogin={props.onLogin}
          />

          <Button
            color="secondary"
            component={Link}
            to="/forgot-password"
          >
            パスワードのリセットはこちら
          </Button>
          <br />
          <Button
            color="secondary"
            style={{ marginBottom: "20px" }}
            component={Link}
            to="/forgot-id"
          >
            契約IDを忘れたときはこちら
          </Button>
        </Paper>
      </div>
    </div>
  );
}
