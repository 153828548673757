import {
  AxiosError,
} from "axios";
import {
  Configuration,
  ContractApi,
  ContractAccessKeysApi,
  ContractProductsApi,
  ContractLogsApi,
  ContractLoginApi,
  ContractEmailApi,
  ContractTenantApi,
  ProblemDetails,
  ConfigurationParameters,
} from './axcloud';

export function createConfiguration(accessToken?: string): Configuration {
  const param: ConfigurationParameters = {
    basePath: process.env.REACT_APP_DAIJIN_AX_CLOUD_API_URI,
  };
  if (accessToken) {
    param.apiKey = `Bearer ${accessToken}`;
  }
  return new Configuration(param);
}

export function createContractLoginApi(): ContractLoginApi {
  const config = createConfiguration();
  return new ContractLoginApi(config);
}

export function createContractApi(accessToken?: string): ContractApi {
  const config = createConfiguration(accessToken);
  return new ContractApi(config);
}

export function createContractAccessKeysApi(accessToken?: string): ContractAccessKeysApi {
  const config = createConfiguration(accessToken);
  return new ContractAccessKeysApi(config);
}

export function createContractProductsApi(accessToken?: string): ContractProductsApi {
  const config = createConfiguration(accessToken);
  return new ContractProductsApi(config);
}

export function createContractLogsApi(accessToken?: string): ContractLogsApi {
  const config = createConfiguration(accessToken);
  return new ContractLogsApi(config);
}

export function createContractEmailApi(accessToken?: string): ContractEmailApi {
  const config = createConfiguration(accessToken);
  return new ContractEmailApi(config);
}

export function createContractTenantApi(accessToken?: string): ContractTenantApi {
  const config = createConfiguration(accessToken);
  return new ContractTenantApi(config);
}

function isAxiosError(error: any): error is AxiosError {
  if (error.response && error.response?.data) {
    return true;
  }
  return false;
}

export function isProblemDetails(data: any): data is ProblemDetails {
  if (data?.detail) {
    return true;
  }
  return false;
}

export function isAxiosErrorProblemDetails(
  error: any
): error is AxiosError<ProblemDetails> {
  if (isAxiosError(error))
  {
    if (isProblemDetails(error.response?.data)) {
      return true;
    }
  }
  return false;
}
