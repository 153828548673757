import React, {
  useCallback,
  useEffect,
} from 'react';
import {
  ProductsTemplate,
} from './ProductsTemplate';
import {
  useTypedSelector,
  useAppDispatch,
} from '../../app/store';
import {
  listContractProducts,
  upgradeContractProduct,
  openUpgrade,
  cancelUpgrade,
} from './productsSlice';
import { ContractProductViewModel } from '../../api/axcloud';

export function ProductsPage() {
  const dispatch = useAppDispatch();
  const state = useTypedSelector(x => ({
    ...x.login,
    ...x.products,
  }));

  useEffect(() => {
    dispatch(listContractProducts({
      accessToken: state.accessToken!,
    }));
  }, [dispatch, state.accessToken]);

  const handleSubmitUpgrade = useCallback((p: ContractProductViewModel, v: string) => {
    dispatch(upgradeContractProduct({
      accessToken: state.accessToken!,
      productId: p.productId!,
      version: v,
    })).then(() => {
      // アップグレードに成功したら一覧を更新する
      return dispatch(listContractProducts({
        accessToken: state.accessToken!,
      }));
    });
  }, [dispatch, state.accessToken]);

  return (
    <ProductsTemplate
      versions={state.products}
      upgradingProduct={state.upgradingProduct}
      onOpenUpgrade={x => dispatch(openUpgrade(x))}
      onCancelUpgrade={() => dispatch(cancelUpgrade())}
      onSubmitUpgrade={handleSubmitUpgrade}
    />
  );
}
