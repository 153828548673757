import React, {
  useState,
  useCallback,
} from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Paper,
  Typography,
	Button,
} from '@material-ui/core';
import {
  ResetPasswordDialog,
  ResetPasswordDialogValues,
} from './ResetPasswordDialog';
import {
  useAppDispatch,
  useTypedSelector,
} from '../../app/store';
import {
  resetAdministratorPassword,
} from "./securitySlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    tableCell: {
      width: "30%",
    },
  }),
);

export const SecurityPage: React.VFC<{}> = (props) => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();
  const state = useTypedSelector(x => ({
    ...x.login,
    ...x.security,
  }));
  const [openReset, setOpenReset] = useState(false);
  const handleResetClick = useCallback(() => {
    setOpenReset(true);
  }, [setOpenReset]);
  const handleClose = useCallback(() => {
    setOpenReset(false);
  }, [setOpenReset]);
  const handleSubmit = useCallback(async (values: ResetPasswordDialogValues) => {
    await dispatch(resetAdministratorPassword({
      accessToken: state.accessToken!,
      newPassword: values.newPassword,
      newPasswordConfirmation: values.newPasswordConfirmation,
    }));
    setOpenReset(false);
  }, [setOpenReset, state, dispatch]);

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          大臣AXクラウドシステム管理者
        </Typography>

        <Typography>
          大臣AXクラウドのシステム管理者(Administrator)のパスワードを忘れてしまった場合、<br/>
          ここでパスワードをリセットできます。
        </Typography>

        <Button
          color="secondary"
          variant="contained"
          onClick={handleResetClick}
        >
          パスワードをリセット
        </Button>
      </Paper>

      <ResetPasswordDialog
        open={openReset}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </>
	);
};
