import React  from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export interface RegenerateAccessKeyDialogProps {
  open: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export const RegenerateAccessKeyDialog: React.FC<RegenerateAccessKeyDialogProps> = ({
  open,
  onCancel,
  onSubmit,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}>
      <DialogTitle>アクセスキーの再生成</DialogTitle>
      <DialogContent>
        <DialogContentText>
          アクセスキーを再生成してもよろしいですか？
          アクセスキーを再生成すると、以前のアクセスキーは使えなくなります。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="secondary">
          再生成
        </Button>
        <Button onClick={onCancel}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
}
