import React, {
  useState,
} from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  AccountCircle,
  ArrowDropDown,
} from '@material-ui/icons';
import {
  useTypedSelector,
  useAppDispatch,
} from '../app/store';
import {
  logout,
} from '../features/login/loginSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
  }),
);

export function NavBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const state = useTypedSelector(x => x.login);
  const dispatch = useAppDispatch();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    dispatch(logout());
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
    <Toolbar>
      <Typography variant="h6" noWrap>
        大臣 AX クラウドポータル
      </Typography>
      <div className={classes.grow} />
      <div>
        {state.loggedIn && (
          <>
            <Button
              color="inherit"
              onClick={handleMenu}
            >
              <AccountCircle />
              {state.companyName}
              <ArrowDropDown />
            </Button>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>
                ログアウト
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    </Toolbar>
  </AppBar>
  );
}
