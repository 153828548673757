/* tslint:disable */
/* eslint-disable */
/**
 * 大臣 AX クラウド管理 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * アクセスキー情報
 * @export
 * @interface AccessKeyInputModel
 */
export interface AccessKeyInputModel {
    /**
     * アクセスキー名
     * @type {string}
     * @memberof AccessKeyInputModel
     */
    name: string;
}
/**
 * アクセスキー
 * @export
 * @interface AccessKeyViewModel
 */
export interface AccessKeyViewModel {
    /**
     * アクセスキー
     * @type {string}
     * @memberof AccessKeyViewModel
     */
    id?: string | null;
    /**
     * テナント ID
     * @type {string}
     * @memberof AccessKeyViewModel
     */
    tenantId?: string | null;
    /**
     * アクセスキー名
     * @type {string}
     * @memberof AccessKeyViewModel
     */
    name?: string | null;
    /**
     * アクセスキーの値
     * @type {string}
     * @memberof AccessKeyViewModel
     */
    value?: string | null;
    /**
     * プライマリーなアクセスキーかどうか
     * @type {boolean}
     * @memberof AccessKeyViewModel
     */
    isPrimary?: boolean;
}
/**
 * アプリの詳細
 * @export
 * @interface ApplicationDetailsViewModel
 */
export interface ApplicationDetailsViewModel {
    /**
     * アプリ ID
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    id?: string | null;
    /**
     * アプリ名
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    name?: string | null;
    /**
     * 製品 ID
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    productId?: string | null;
    /**
     * アプリの説明
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    description?: string | null;
    /**
     * 
     * @type {ApplicationType}
     * @memberof ApplicationDetailsViewModel
     */
    type?: ApplicationType;
    /**
     * クライアント ID
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    clientId?: string | null;
    /**
     * クライアントシークレット
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    clientSecret?: string | null;
    /**
     * リダイレクト URI
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    redirectUri?: string | null;
    /**
     * Webhook を使用する
     * @type {boolean}
     * @memberof ApplicationDetailsViewModel
     */
    webHookEnabled?: boolean;
    /**
     * Webhook URL
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    webHookUrl?: string | null;
    /**
     * Webhook Secret
     * @type {string}
     * @memberof ApplicationDetailsViewModel
     */
    webHookSecret?: string | null;
    /**
     * 
     * @type {WebHookFilterMode}
     * @memberof ApplicationDetailsViewModel
     */
    webHookFilterMode?: WebHookFilterMode;
    /**
     * アプリがハンドルする Web フックのイベント
     * @type {Array<string>}
     * @memberof ApplicationDetailsViewModel
     */
    webHookEvents?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationType {
    Web = 'Web',
    Native = 'Native'
}

/**
 * アプリ
 * @export
 * @interface ApplicationViewModel
 */
export interface ApplicationViewModel {
    /**
     * アプリ ID
     * @type {string}
     * @memberof ApplicationViewModel
     */
    id?: string | null;
    /**
     * アプリ名
     * @type {string}
     * @memberof ApplicationViewModel
     */
    name?: string | null;
}
/**
 * パスワード変更要求
 * @export
 * @interface ChangeContractPasswordInputModel
 */
export interface ChangeContractPasswordInputModel {
    /**
     * 現在のパスワード
     * @type {string}
     * @memberof ChangeContractPasswordInputModel
     */
    currentPassword: string;
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof ChangeContractPasswordInputModel
     */
    newPassword: string;
}
/**
 * 契約の詳細
 * @export
 * @interface ContractDetailsViewModel
 */
export interface ContractDetailsViewModel {
    /**
     * 契約 ID
     * @type {string}
     * @memberof ContractDetailsViewModel
     */
    contractId?: string | null;
    /**
     * 会社名
     * @type {string}
     * @memberof ContractDetailsViewModel
     */
    companyName?: string | null;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ContractDetailsViewModel
     */
    email?: string | null;
    /**
     * メールアドレス(予備1)
     * @type {string}
     * @memberof ContractDetailsViewModel
     */
    subEmail1?: string | null;
    /**
     * メールアドレス(予備2)
     * @type {string}
     * @memberof ContractDetailsViewModel
     */
    subEmail2?: string | null;
    /**
     * 認証コード
     * @type {string}
     * @memberof ContractDetailsViewModel
     */
    authenticationCode?: string | null;
    /**
     * 利用開始日時
     * @type {string}
     * @memberof ContractDetailsViewModel
     */
    startAt?: string;
    /**
     * 利用終了日時
     * @type {string}
     * @memberof ContractDetailsViewModel
     */
    endAt?: string;
    /**
     * 
     * @type {ContractPlatformViewModel}
     * @memberof ContractDetailsViewModel
     */
    platform?: ContractPlatformViewModel;
    /**
     * 追加データ容量
     * @type {number}
     * @memberof ContractDetailsViewModel
     */
    additionalDataCapacity?: number;
    /**
     * 追加データ領域数
     * @type {number}
     * @memberof ContractDetailsViewModel
     */
    additionalDataAreaCount?: number;
    /**
     * 契約製品の一覧
     * @type {Array<ContractProductDetailsViewModel>}
     * @memberof ContractDetailsViewModel
     */
    products?: Array<ContractProductDetailsViewModel> | null;
}
/**
 * 登録する操作ログ
 * @export
 * @interface ContractLogInputModel
 */
export interface ContractLogInputModel {
    /**
     * 操作
     * @type {string}
     * @memberof ContractLogInputModel
     */
    action: string;
}
/**
 * 操作ログ
 * @export
 * @interface ContractLogViewModel
 */
export interface ContractLogViewModel {
    /**
     * ID
     * @type {string}
     * @memberof ContractLogViewModel
     */
    id?: string | null;
    /**
     * 日時
     * @type {string}
     * @memberof ContractLogViewModel
     */
    loggedAt?: string;
    /**
     * IP アドレス
     * @type {string}
     * @memberof ContractLogViewModel
     */
    ipAddress?: string | null;
    /**
     * 操作
     * @type {string}
     * @memberof ContractLogViewModel
     */
    action?: string | null;
}
/**
 * 契約ログインの入力
 * @export
 * @interface ContractLoginInputModel
 */
export interface ContractLoginInputModel {
    /**
     * 契約 ID
     * @type {string}
     * @memberof ContractLoginInputModel
     */
    contractId: string;
    /**
     * パスワード
     * @type {string}
     * @memberof ContractLoginInputModel
     */
    password: string;
}
/**
 * 操作ログ一覧
 * @export
 * @interface ContractLogsViewModel
 */
export interface ContractLogsViewModel {
    /**
     * 全体の件数
     * @type {number}
     * @memberof ContractLogsViewModel
     */
    totalCount?: number;
    /**
     * 操作ログ一覧
     * @type {Array<ContractLogViewModel>}
     * @memberof ContractLogsViewModel
     */
    logs?: Array<ContractLogViewModel> | null;
}
/**
 * 契約したオプション
 * @export
 * @interface ContractOptionInputModel
 */
export interface ContractOptionInputModel {
    /**
     * 製品 ID
     * @type {string}
     * @memberof ContractOptionInputModel
     */
    productId: string;
    /**
     * オプション ID
     * @type {string}
     * @memberof ContractOptionInputModel
     */
    optionId: string;
    /**
     * オプション名
     * @type {string}
     * @memberof ContractOptionInputModel
     */
    optionName: string;
    /**
     * ライセンス数
     * @type {number}
     * @memberof ContractOptionInputModel
     */
    licenseCount?: number | null;
}
/**
 * 契約したオプション
 * @export
 * @interface ContractOptionViewModel
 */
export interface ContractOptionViewModel {
    /**
     * 製品 ID
     * @type {string}
     * @memberof ContractOptionViewModel
     */
    productId?: string | null;
    /**
     * オプション ID
     * @type {string}
     * @memberof ContractOptionViewModel
     */
    optionId?: string | null;
    /**
     * ライセンス数
     * @type {string}
     * @memberof ContractOptionViewModel
     */
    optionName?: string | null;
    /**
     * ライセンス数
     * @type {number}
     * @memberof ContractOptionViewModel
     */
    licenseCount?: number | null;
}
/**
 * 契約の基盤
 * @export
 * @interface ContractPlatformViewModel
 */
export interface ContractPlatformViewModel {
    /**
     * ID
     * @type {string}
     * @memberof ContractPlatformViewModel
     */
    id?: string | null;
    /**
     * 名称
     * @type {string}
     * @memberof ContractPlatformViewModel
     */
    name?: string | null;
    /**
     * ライセンス数
     * @type {number}
     * @memberof ContractPlatformViewModel
     */
    userCount?: number;
    /**
     * 標準データ容量
     * @type {number}
     * @memberof ContractPlatformViewModel
     */
    dataCapacity?: number;
}
/**
 * 契約製品の詳細
 * @export
 * @interface ContractProductDetailsViewModel
 */
export interface ContractProductDetailsViewModel {
    /**
     * 製品ID
     * @type {string}
     * @memberof ContractProductDetailsViewModel
     */
    productId?: string | null;
    /**
     * 製品名
     * @type {string}
     * @memberof ContractProductDetailsViewModel
     */
    productName?: string | null;
    /**
     * ライセンス数
     * @type {number}
     * @memberof ContractProductDetailsViewModel
     */
    licenseCount?: number;
    /**
     * シリアル番号
     * @type {string}
     * @memberof ContractProductDetailsViewModel
     */
    serialNumber?: string | null;
    /**
     * DMSS番号
     * @type {string}
     * @memberof ContractProductDetailsViewModel
     */
    dmssNumber?: string | null;
    /**
     * 契約したオプション
     * @type {Array<ContractOptionViewModel>}
     * @memberof ContractProductDetailsViewModel
     */
    options?: Array<ContractOptionViewModel> | null;
}
/**
 * 組織に紐づくテナントが利用している製品
 * @export
 * @interface ContractProductViewModel
 */
export interface ContractProductViewModel {
    /**
     * テナント ID
     * @type {string}
     * @memberof ContractProductViewModel
     */
    tenantId?: string | null;
    /**
     * 製品 ID
     * @type {string}
     * @memberof ContractProductViewModel
     */
    productId?: string | null;
    /**
     * 製品名
     * @type {string}
     * @memberof ContractProductViewModel
     */
    productName?: string | null;
    /**
     * 製品バージョン
     * @type {string}
     * @memberof ContractProductViewModel
     */
    version?: string | null;
    /**
     * シリアル番号
     * @type {string}
     * @memberof ContractProductViewModel
     */
    serialNumber?: string | null;
    /**
     * アップグレード可能かどうか
     * @type {boolean}
     * @memberof ContractProductViewModel
     */
    canUpgrade?: boolean;
    /**
     * アップグレード可能なバージョンの一覧
     * @type {Array<string>}
     * @memberof ContractProductViewModel
     */
    upgradableVersions?: Array<string> | null;
}
/**
 * 契約
 * @export
 * @interface ContractViewModel
 */
export interface ContractViewModel {
    /**
     * 契約 ID
     * @type {string}
     * @memberof ContractViewModel
     */
    id?: string | null;
    /**
     * 会社名
     * @type {string}
     * @memberof ContractViewModel
     */
    companyName?: string | null;
    /**
     * パスワード変更が必要かどうか
     * @type {boolean}
     * @memberof ContractViewModel
     */
    requiresPasswordChange?: boolean;
}
/**
 * メール送信テストの入力
 * @export
 * @interface EmailTestInputModel
 */
export interface EmailTestInputModel {
    /**
     * メールアドレス
     * @type {string}
     * @memberof EmailTestInputModel
     */
    email: string;
}
/**
 * 契約 ID 送信要求
 * @export
 * @interface ForgotContractIdInputModel
 */
export interface ForgotContractIdInputModel {
    /**
     * メールアドレス
     * @type {string}
     * @memberof ForgotContractIdInputModel
     */
    email: string;
}
/**
 * パスワードリセットトークン要求
 * @export
 * @interface ForgotContractPasswordInputModel
 */
export interface ForgotContractPasswordInputModel {
    /**
     * 契約 ID
     * @type {string}
     * @memberof ForgotContractPasswordInputModel
     */
    contractId: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ForgotContractPasswordInputModel
     */
    email: string;
}
/**
 * WebHook イベント
 * @export
 * @interface HookEventViewModel
 */
export interface HookEventViewModel {
    /**
     * イベント名
     * @type {string}
     * @memberof HookEventViewModel
     */
    eventName?: string | null;
    /**
     * 表示用名称
     * @type {string}
     * @memberof HookEventViewModel
     */
    displayName?: string | null;
    /**
     * イベントの説明
     * @type {string}
     * @memberof HookEventViewModel
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface Operation
 */
export interface Operation {
    /**
     * 
     * @type {OperationType}
     * @memberof Operation
     */
    operationType?: OperationType;
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    op?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    from?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Operation
     */
    value?: any | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationType {
    Add = 'Add',
    Remove = 'Remove',
    Replace = 'Replace',
    Move = 'Move',
    Copy = 'Copy',
    Test = 'Test',
    Invalid = 'Invalid'
}

/**
 * パスワードポリシー
 * @export
 * @interface PasswordPolicyViewModel
 */
export interface PasswordPolicyViewModel {
    /**
     * 最小の長さ
     * @type {number}
     * @memberof PasswordPolicyViewModel
     */
    minLength?: number;
    /**
     * 複雑なパスワードが必要かどうか
     * @type {boolean}
     * @memberof PasswordPolicyViewModel
     */
    requireComplexity?: boolean;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * アプリの登録内容
 * @export
 * @interface RegisterApplicationInputModel
 */
export interface RegisterApplicationInputModel {
    /**
     * アプリ名
     * @type {string}
     * @memberof RegisterApplicationInputModel
     */
    name: string;
    /**
     * 概要
     * @type {string}
     * @memberof RegisterApplicationInputModel
     */
    description?: string | null;
    /**
     * 連係する製品
     * @type {string}
     * @memberof RegisterApplicationInputModel
     */
    productId: string;
    /**
     * 
     * @type {ApplicationType}
     * @memberof RegisterApplicationInputModel
     */
    type: ApplicationType;
}
/**
 * テナント情報
 * @export
 * @interface RegisterTenantInputModel
 */
export interface RegisterTenantInputModel {
    /**
     * 契約 ID
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    contractId: string;
    /**
     * 会社名1
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    companyName: string;
    /**
     * 利用期間開始日
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    startDate: string;
    /**
     * 利用期間終了日
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    endDate: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    email: string;
    /**
     * 認証コード
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    authenticationCode?: string | null;
    /**
     * ポータルにログインするための初期パスワード
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    password: string;
    /**
     * テナント用 OH_SYSTEM のパスワード
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    ohSystemPassword: string;
    /**
     * テナント用 OH_DB_CREATOR のパスワード
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    ohDbCreatorPassword: string;
    /**
     * 利用ユーザー
     * @type {string}
     * @memberof RegisterTenantInputModel
     */
    platformId: string;
    /**
     * 追加データ容量
     * @type {number}
     * @memberof RegisterTenantInputModel
     */
    additionalDataCapacity?: number;
    /**
     * 追加データ領域数
     * @type {number}
     * @memberof RegisterTenantInputModel
     */
    additionalDataAreaCount?: number;
    /**
     * テナント利用製品
     * @type {Array<TenantProductInputModel>}
     * @memberof RegisterTenantInputModel
     */
    products: Array<TenantProductInputModel>;
    /**
     * 契約したオプション
     * @type {Array<ContractOptionInputModel>}
     * @memberof RegisterTenantInputModel
     */
    options?: Array<ContractOptionInputModel> | null;
}
/**
 * 大臣AXクラウドのシステム管理者の新しいパスワード
 * @export
 * @interface ResetAdministratorPasswordInputModel
 */
export interface ResetAdministratorPasswordInputModel {
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof ResetAdministratorPasswordInputModel
     */
    newPassword: string;
    /**
     * 新しいパスワード(確認用)
     * @type {string}
     * @memberof ResetAdministratorPasswordInputModel
     */
    newPasswordConfirmation: string;
}
/**
 * パスワードリセット要求
 * @export
 * @interface ResetContractPasswordInputModel
 */
export interface ResetContractPasswordInputModel {
    /**
     * 契約 ID
     * @type {string}
     * @memberof ResetContractPasswordInputModel
     */
    contractId: string;
    /**
     * パスワードリセットトークン
     * @type {string}
     * @memberof ResetContractPasswordInputModel
     */
    token: string;
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof ResetContractPasswordInputModel
     */
    newPassword: string;
}
/**
 * ストレージ情報
 * @export
 * @interface StorageInfoViewModel
 */
export interface StorageInfoViewModel {
    /**
     * 契約サイズ（MB）を取得します。
     * @type {number}
     * @memberof StorageInfoViewModel
     */
    contractCapacity?: number;
    /**
     * 使用済み領域のサイズ（MB）を取得します。
     * @type {number}
     * @memberof StorageInfoViewModel
     */
    usage?: number;
}
/**
 * テナント利用製品
 * @export
 * @interface TenantProductInputModel
 */
export interface TenantProductInputModel {
    /**
     * 製品 ID
     * @type {string}
     * @memberof TenantProductInputModel
     */
    productId: string;
    /**
     * 製品名
     * @type {string}
     * @memberof TenantProductInputModel
     */
    productName: string;
    /**
     * ライセンス数
     * @type {number}
     * @memberof TenantProductInputModel
     */
    licenseCount: number;
    /**
     * シリアル番号
     * @type {string}
     * @memberof TenantProductInputModel
     */
    serialNumber: string;
    /**
     * DMSS 番号
     * @type {string}
     * @memberof TenantProductInputModel
     */
    dmssNumber: string;
}
/**
 * テナント
 * @export
 * @interface TenantViewModel
 */
export interface TenantViewModel {
    /**
     * テナント ID
     * @type {string}
     * @memberof TenantViewModel
     */
    id?: string | null;
    /**
     * テナント名
     * @type {string}
     * @memberof TenantViewModel
     */
    name?: string | null;
    /**
     * エラスティックプール名
     * @type {string}
     * @memberof TenantViewModel
     */
    elasticPoolName?: string | null;
    /**
     * SQL Server 名
     * @type {string}
     * @memberof TenantViewModel
     */
    sqlServerName?: string | null;
}
/**
 * アクセストークン
 * @export
 * @interface TokenViewModel
 */
export interface TokenViewModel {
    /**
     * アクセストークン
     * @type {string}
     * @memberof TokenViewModel
     */
    accessToken?: string | null;
    /**
     * 有効期限
     * @type {string}
     * @memberof TokenViewModel
     */
    expires?: string;
    /**
     * 
     * @type {ContractViewModel}
     * @memberof TokenViewModel
     */
    contract?: ContractViewModel;
}
/**
 * アプリの変更内容
 * @export
 * @interface UpdateApplicationInputModel
 */
export interface UpdateApplicationInputModel {
    /**
     * アプリ名
     * @type {string}
     * @memberof UpdateApplicationInputModel
     */
    name: string;
    /**
     * 概要
     * @type {string}
     * @memberof UpdateApplicationInputModel
     */
    description?: string | null;
    /**
     * コールバック URL
     * @type {string}
     * @memberof UpdateApplicationInputModel
     */
    redirectUri: string;
    /**
     * Webhook を使用する
     * @type {boolean}
     * @memberof UpdateApplicationInputModel
     */
    webHookEnabled: boolean;
    /**
     * Webhook URL
     * @type {string}
     * @memberof UpdateApplicationInputModel
     */
    webHookUrl?: string | null;
    /**
     * Webhook Secret
     * @type {string}
     * @memberof UpdateApplicationInputModel
     */
    webHookSecret?: string | null;
    /**
     * 
     * @type {WebHookFilterMode}
     * @memberof UpdateApplicationInputModel
     */
    webHookFilterMode?: WebHookFilterMode;
    /**
     * Webhook イベント
     * @type {Array<string>}
     * @memberof UpdateApplicationInputModel
     */
    webHookEvents?: Array<string> | null;
}
/**
 * 製品のアップグレード情報
 * @export
 * @interface UpgradeProductInputModel
 */
export interface UpgradeProductInputModel {
    /**
     * 新しいバージョン
     * @type {string}
     * @memberof UpgradeProductInputModel
     */
    version: string;
}
/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    instance?: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    errors?: { [key: string]: Array<string>; } | null;
}
/**
 * Web フックのフィルタのモード
 * @export
 * @enum {string}
 */
export enum WebHookFilterMode {
    All = 'All',
    Select = 'Select'
}


/**
 * ApplicationsApi - axios parameter creator
 * @export
 */
export const ApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アプリを削除します。
         * @param {string} applicationId アプリ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplication: async (applicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('deleteApplication', 'applicationId', applicationId)
            const localVarPath = `/api/applications/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アプリの詳細を取得します。
         * @param {string} applicationId アプリ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication: async (applicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplication', 'applicationId', applicationId)
            const localVarPath = `/api/applications/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アプリの一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApplications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 新しいアプリを登録します。
         * @param {RegisterApplicationInputModel} [registerApplicationInputModel] アプリの登録内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerApplication: async (registerApplicationInputModel?: RegisterApplicationInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerApplicationInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アプリを更新します。
         * @param {string} applicationId アプリ ID
         * @param {UpdateApplicationInputModel} [updateApplicationInputModel] アプリの変更内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication: async (applicationId: string, updateApplicationInputModel?: UpdateApplicationInputModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('updateApplication', 'applicationId', applicationId)
            const localVarPath = `/api/applications/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApplicationInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsApi - functional programming interface
 * @export
 */
export const ApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アプリを削除します。
         * @param {string} applicationId アプリ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplication(applicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplication(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アプリの詳細を取得します。
         * @param {string} applicationId アプリ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication(applicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplication(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アプリの一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApplications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listApplications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 新しいアプリを登録します。
         * @param {RegisterApplicationInputModel} [registerApplicationInputModel] アプリの登録内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerApplication(registerApplicationInputModel?: RegisterApplicationInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerApplication(registerApplicationInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アプリを更新します。
         * @param {string} applicationId アプリ ID
         * @param {UpdateApplicationInputModel} [updateApplicationInputModel] アプリの変更内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplication(applicationId: string, updateApplicationInputModel?: UpdateApplicationInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplication(applicationId, updateApplicationInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationsApi - factory interface
 * @export
 */
export const ApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary アプリを削除します。
         * @param {string} applicationId アプリ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplication(applicationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteApplication(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アプリの詳細を取得します。
         * @param {string} applicationId アプリ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication(applicationId: string, options?: any): AxiosPromise<ApplicationDetailsViewModel> {
            return localVarFp.getApplication(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アプリの一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApplications(options?: any): AxiosPromise<Array<ApplicationViewModel>> {
            return localVarFp.listApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 新しいアプリを登録します。
         * @param {RegisterApplicationInputModel} [registerApplicationInputModel] アプリの登録内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerApplication(registerApplicationInputModel?: RegisterApplicationInputModel, options?: any): AxiosPromise<ApplicationViewModel> {
            return localVarFp.registerApplication(registerApplicationInputModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アプリを更新します。
         * @param {string} applicationId アプリ ID
         * @param {UpdateApplicationInputModel} [updateApplicationInputModel] アプリの変更内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication(applicationId: string, updateApplicationInputModel?: UpdateApplicationInputModel, options?: any): AxiosPromise<ApplicationDetailsViewModel> {
            return localVarFp.updateApplication(applicationId, updateApplicationInputModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
export class ApplicationsApi extends BaseAPI {
    /**
     * 
     * @summary アプリを削除します。
     * @param {string} applicationId アプリ ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public deleteApplication(applicationId: string, options?: any) {
        return ApplicationsApiFp(this.configuration).deleteApplication(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アプリの詳細を取得します。
     * @param {string} applicationId アプリ ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getApplication(applicationId: string, options?: any) {
        return ApplicationsApiFp(this.configuration).getApplication(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アプリの一覧を取得します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public listApplications(options?: any) {
        return ApplicationsApiFp(this.configuration).listApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 新しいアプリを登録します。
     * @param {RegisterApplicationInputModel} [registerApplicationInputModel] アプリの登録内容
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public registerApplication(registerApplicationInputModel?: RegisterApplicationInputModel, options?: any) {
        return ApplicationsApiFp(this.configuration).registerApplication(registerApplicationInputModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アプリを更新します。
     * @param {string} applicationId アプリ ID
     * @param {UpdateApplicationInputModel} [updateApplicationInputModel] アプリの変更内容
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public updateApplication(applicationId: string, updateApplicationInputModel?: UpdateApplicationInputModel, options?: any) {
        return ApplicationsApiFp(this.configuration).updateApplication(applicationId, updateApplicationInputModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary パスワードを変更します。
         * @param {ChangeContractPasswordInputModel} [changeContractPasswordInputModel] パスワード変更要求
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeContractPassword: async (changeContractPasswordInputModel?: ChangeContractPasswordInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeContractPasswordInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定したメールアドレスに紐づく契約 ID をメールで通知します。
         * @param {ForgotContractIdInputModel} [forgotContractIdInputModel] 契約IDの送信先
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotContractId: async (forgotContractIdInputModel?: ForgotContractIdInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/id/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotContractIdInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定した契約 ID に紐づくメールアドレスに、パスワードリセットトークンをメールで送信します。
         * @param {ForgotContractPasswordInputModel} [forgotContractPasswordInputModel] パスワードリセットトークン送信先
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotContractPassword: async (forgotContractPasswordInputModel?: ForgotContractPasswordInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/password/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotContractPasswordInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 契約の詳細を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractDetails: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 契約情報を変更します。
         * @param {Array<Operation>} [operation] 変更内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContract: async (operation?: Array<Operation>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスワードをリセットします。
         * @param {ResetContractPasswordInputModel} [resetContractPasswordInputModel] パスワードリセット要求
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetContractPassword: async (resetContractPasswordInputModel?: ResetContractPasswordInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetContractPasswordInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary パスワードを変更します。
         * @param {ChangeContractPasswordInputModel} [changeContractPasswordInputModel] パスワード変更要求
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeContractPassword(changeContractPasswordInputModel?: ChangeContractPasswordInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeContractPassword(changeContractPasswordInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定したメールアドレスに紐づく契約 ID をメールで通知します。
         * @param {ForgotContractIdInputModel} [forgotContractIdInputModel] 契約IDの送信先
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotContractId(forgotContractIdInputModel?: ForgotContractIdInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotContractId(forgotContractIdInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定した契約 ID に紐づくメールアドレスに、パスワードリセットトークンをメールで送信します。
         * @param {ForgotContractPasswordInputModel} [forgotContractPasswordInputModel] パスワードリセットトークン送信先
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotContractPassword(forgotContractPasswordInputModel?: ForgotContractPasswordInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotContractPassword(forgotContractPasswordInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 契約の詳細を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractDetails(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 契約情報を変更します。
         * @param {Array<Operation>} [operation] 変更内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContract(operation?: Array<Operation>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchContract(operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスワードをリセットします。
         * @param {ResetContractPasswordInputModel} [resetContractPasswordInputModel] パスワードリセット要求
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetContractPassword(resetContractPasswordInputModel?: ResetContractPasswordInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetContractPassword(resetContractPasswordInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 
         * @summary パスワードを変更します。
         * @param {ChangeContractPasswordInputModel} [changeContractPasswordInputModel] パスワード変更要求
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeContractPassword(changeContractPasswordInputModel?: ChangeContractPasswordInputModel, options?: any): AxiosPromise<void> {
            return localVarFp.changeContractPassword(changeContractPasswordInputModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定したメールアドレスに紐づく契約 ID をメールで通知します。
         * @param {ForgotContractIdInputModel} [forgotContractIdInputModel] 契約IDの送信先
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotContractId(forgotContractIdInputModel?: ForgotContractIdInputModel, options?: any): AxiosPromise<void> {
            return localVarFp.forgotContractId(forgotContractIdInputModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定した契約 ID に紐づくメールアドレスに、パスワードリセットトークンをメールで送信します。
         * @param {ForgotContractPasswordInputModel} [forgotContractPasswordInputModel] パスワードリセットトークン送信先
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotContractPassword(forgotContractPasswordInputModel?: ForgotContractPasswordInputModel, options?: any): AxiosPromise<void> {
            return localVarFp.forgotContractPassword(forgotContractPasswordInputModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 契約の詳細を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractDetails(options?: any): AxiosPromise<ContractDetailsViewModel> {
            return localVarFp.getContractDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 契約情報を変更します。
         * @param {Array<Operation>} [operation] 変更内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContract(operation?: Array<Operation>, options?: any): AxiosPromise<ContractDetailsViewModel> {
            return localVarFp.patchContract(operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスワードをリセットします。
         * @param {ResetContractPasswordInputModel} [resetContractPasswordInputModel] パスワードリセット要求
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetContractPassword(resetContractPasswordInputModel?: ResetContractPasswordInputModel, options?: any): AxiosPromise<void> {
            return localVarFp.resetContractPassword(resetContractPasswordInputModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary パスワードを変更します。
     * @param {ChangeContractPasswordInputModel} [changeContractPasswordInputModel] パスワード変更要求
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public changeContractPassword(changeContractPasswordInputModel?: ChangeContractPasswordInputModel, options?: any) {
        return ContractApiFp(this.configuration).changeContractPassword(changeContractPasswordInputModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定したメールアドレスに紐づく契約 ID をメールで通知します。
     * @param {ForgotContractIdInputModel} [forgotContractIdInputModel] 契約IDの送信先
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public forgotContractId(forgotContractIdInputModel?: ForgotContractIdInputModel, options?: any) {
        return ContractApiFp(this.configuration).forgotContractId(forgotContractIdInputModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定した契約 ID に紐づくメールアドレスに、パスワードリセットトークンをメールで送信します。
     * @param {ForgotContractPasswordInputModel} [forgotContractPasswordInputModel] パスワードリセットトークン送信先
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public forgotContractPassword(forgotContractPasswordInputModel?: ForgotContractPasswordInputModel, options?: any) {
        return ContractApiFp(this.configuration).forgotContractPassword(forgotContractPasswordInputModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 契約の詳細を取得します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractDetails(options?: any) {
        return ContractApiFp(this.configuration).getContractDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 契約情報を変更します。
     * @param {Array<Operation>} [operation] 変更内容
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public patchContract(operation?: Array<Operation>, options?: any) {
        return ContractApiFp(this.configuration).patchContract(operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスワードをリセットします。
     * @param {ResetContractPasswordInputModel} [resetContractPasswordInputModel] パスワードリセット要求
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public resetContractPassword(resetContractPasswordInputModel?: ResetContractPasswordInputModel, options?: any) {
        return ContractApiFp(this.configuration).resetContractPassword(resetContractPasswordInputModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractAccessKeysApi - axios parameter creator
 * @export
 */
export const ContractAccessKeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アクセスキーを削除します。
         * @param {string} accessKeyId アクセスキー ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessKey: async (accessKeyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKeyId' is not null or undefined
            assertParamExists('deleteAccessKey', 'accessKeyId', accessKeyId)
            const localVarPath = `/api/contract/access-keys/{accessKeyId}`
                .replace(`{${"accessKeyId"}}`, encodeURIComponent(String(accessKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プライマリなアクセスキーを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrimaryAccessKey: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/access-keys/primary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アクセスキーの一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractAccessKeys: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/access-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プライマリなアクセスキーを再生成します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regeneratePrimaryAccessKey: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/access-keys/primary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アクセスキーを登録します。
         * @param {AccessKeyInputModel} [accessKeyInputModel] アクセスキーの情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerContractAccessKey: async (accessKeyInputModel?: AccessKeyInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/access-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessKeyInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アクセスキーを更新します。
         * @param {string} accessKeyId アクセスキー ID
         * @param {AccessKeyInputModel} [accessKeyInputModel] アクセスキーの情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessKey: async (accessKeyId: string, accessKeyInputModel?: AccessKeyInputModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKeyId' is not null or undefined
            assertParamExists('updateAccessKey', 'accessKeyId', accessKeyId)
            const localVarPath = `/api/contract/access-keys/{accessKeyId}`
                .replace(`{${"accessKeyId"}}`, encodeURIComponent(String(accessKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessKeyInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractAccessKeysApi - functional programming interface
 * @export
 */
export const ContractAccessKeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractAccessKeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アクセスキーを削除します。
         * @param {string} accessKeyId アクセスキー ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccessKey(accessKeyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccessKey(accessKeyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary プライマリなアクセスキーを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrimaryAccessKey(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKeyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrimaryAccessKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アクセスキーの一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContractAccessKeys(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessKeyViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContractAccessKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary プライマリなアクセスキーを再生成します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regeneratePrimaryAccessKey(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKeyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regeneratePrimaryAccessKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アクセスキーを登録します。
         * @param {AccessKeyInputModel} [accessKeyInputModel] アクセスキーの情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerContractAccessKey(accessKeyInputModel?: AccessKeyInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKeyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerContractAccessKey(accessKeyInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アクセスキーを更新します。
         * @param {string} accessKeyId アクセスキー ID
         * @param {AccessKeyInputModel} [accessKeyInputModel] アクセスキーの情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccessKey(accessKeyId: string, accessKeyInputModel?: AccessKeyInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKeyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessKey(accessKeyId, accessKeyInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractAccessKeysApi - factory interface
 * @export
 */
export const ContractAccessKeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractAccessKeysApiFp(configuration)
    return {
        /**
         * 
         * @summary アクセスキーを削除します。
         * @param {string} accessKeyId アクセスキー ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessKey(accessKeyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAccessKey(accessKeyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プライマリなアクセスキーを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrimaryAccessKey(options?: any): AxiosPromise<AccessKeyViewModel> {
            return localVarFp.getPrimaryAccessKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アクセスキーの一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractAccessKeys(options?: any): AxiosPromise<Array<AccessKeyViewModel>> {
            return localVarFp.listContractAccessKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プライマリなアクセスキーを再生成します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regeneratePrimaryAccessKey(options?: any): AxiosPromise<AccessKeyViewModel> {
            return localVarFp.regeneratePrimaryAccessKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アクセスキーを登録します。
         * @param {AccessKeyInputModel} [accessKeyInputModel] アクセスキーの情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerContractAccessKey(accessKeyInputModel?: AccessKeyInputModel, options?: any): AxiosPromise<AccessKeyViewModel> {
            return localVarFp.registerContractAccessKey(accessKeyInputModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アクセスキーを更新します。
         * @param {string} accessKeyId アクセスキー ID
         * @param {AccessKeyInputModel} [accessKeyInputModel] アクセスキーの情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessKey(accessKeyId: string, accessKeyInputModel?: AccessKeyInputModel, options?: any): AxiosPromise<AccessKeyViewModel> {
            return localVarFp.updateAccessKey(accessKeyId, accessKeyInputModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractAccessKeysApi - object-oriented interface
 * @export
 * @class ContractAccessKeysApi
 * @extends {BaseAPI}
 */
export class ContractAccessKeysApi extends BaseAPI {
    /**
     * 
     * @summary アクセスキーを削除します。
     * @param {string} accessKeyId アクセスキー ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractAccessKeysApi
     */
    public deleteAccessKey(accessKeyId: string, options?: any) {
        return ContractAccessKeysApiFp(this.configuration).deleteAccessKey(accessKeyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プライマリなアクセスキーを取得します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractAccessKeysApi
     */
    public getPrimaryAccessKey(options?: any) {
        return ContractAccessKeysApiFp(this.configuration).getPrimaryAccessKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アクセスキーの一覧を取得します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractAccessKeysApi
     */
    public listContractAccessKeys(options?: any) {
        return ContractAccessKeysApiFp(this.configuration).listContractAccessKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プライマリなアクセスキーを再生成します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractAccessKeysApi
     */
    public regeneratePrimaryAccessKey(options?: any) {
        return ContractAccessKeysApiFp(this.configuration).regeneratePrimaryAccessKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アクセスキーを登録します。
     * @param {AccessKeyInputModel} [accessKeyInputModel] アクセスキーの情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractAccessKeysApi
     */
    public registerContractAccessKey(accessKeyInputModel?: AccessKeyInputModel, options?: any) {
        return ContractAccessKeysApiFp(this.configuration).registerContractAccessKey(accessKeyInputModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アクセスキーを更新します。
     * @param {string} accessKeyId アクセスキー ID
     * @param {AccessKeyInputModel} [accessKeyInputModel] アクセスキーの情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractAccessKeysApi
     */
    public updateAccessKey(accessKeyId: string, accessKeyInputModel?: AccessKeyInputModel, options?: any) {
        return ContractAccessKeysApiFp(this.configuration).updateAccessKey(accessKeyId, accessKeyInputModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractEmailApi - axios parameter creator
 * @export
 */
export const ContractEmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary メールをテスト送信します。
         * @param {EmailTestInputModel} [emailTestInputModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        test: async (emailTestInputModel?: EmailTestInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/email/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTestInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractEmailApi - functional programming interface
 * @export
 */
export const ContractEmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractEmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary メールをテスト送信します。
         * @param {EmailTestInputModel} [emailTestInputModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async test(emailTestInputModel?: EmailTestInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.test(emailTestInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractEmailApi - factory interface
 * @export
 */
export const ContractEmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractEmailApiFp(configuration)
    return {
        /**
         * 
         * @summary メールをテスト送信します。
         * @param {EmailTestInputModel} [emailTestInputModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        test(emailTestInputModel?: EmailTestInputModel, options?: any): AxiosPromise<void> {
            return localVarFp.test(emailTestInputModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractEmailApi - object-oriented interface
 * @export
 * @class ContractEmailApi
 * @extends {BaseAPI}
 */
export class ContractEmailApi extends BaseAPI {
    /**
     * 
     * @summary メールをテスト送信します。
     * @param {EmailTestInputModel} [emailTestInputModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractEmailApi
     */
    public test(emailTestInputModel?: EmailTestInputModel, options?: any) {
        return ContractEmailApiFp(this.configuration).test(emailTestInputModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractLoginApi - axios parameter creator
 * @export
 */
export const ContractLoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 契約 ID とパスワードでログインします。
         * @param {ContractLoginInputModel} [contractLoginInputModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractLogin: async (contractLoginInputModel?: ContractLoginInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractLoginInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractLoginApi - functional programming interface
 * @export
 */
export const ContractLoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractLoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 契約 ID とパスワードでログインします。
         * @param {ContractLoginInputModel} [contractLoginInputModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractLogin(contractLoginInputModel?: ContractLoginInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractLogin(contractLoginInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractLoginApi - factory interface
 * @export
 */
export const ContractLoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractLoginApiFp(configuration)
    return {
        /**
         * 
         * @summary 契約 ID とパスワードでログインします。
         * @param {ContractLoginInputModel} [contractLoginInputModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractLogin(contractLoginInputModel?: ContractLoginInputModel, options?: any): AxiosPromise<TokenViewModel> {
            return localVarFp.contractLogin(contractLoginInputModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractLoginApi - object-oriented interface
 * @export
 * @class ContractLoginApi
 * @extends {BaseAPI}
 */
export class ContractLoginApi extends BaseAPI {
    /**
     * 
     * @summary 契約 ID とパスワードでログインします。
     * @param {ContractLoginInputModel} [contractLoginInputModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractLoginApi
     */
    public contractLogin(contractLoginInputModel?: ContractLoginInputModel, options?: any) {
        return ContractLoginApiFp(this.configuration).contractLogin(contractLoginInputModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractLogsApi - axios parameter creator
 * @export
 */
export const ContractLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 操作ログ一を登録します。
         * @param {ContractLogInputModel} [contractLogInputModel] 登録する操作ログ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContractLog: async (contractLogInputModel?: ContractLogInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractLogInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 操作ログ一覧を取得します。
         * @param {number} [page] ページ番号(0 ～)
         * @param {number} [perPage] ページに表示する件数(1 ～)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractLogs: async (page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['PerPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractLogsApi - functional programming interface
 * @export
 */
export const ContractLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 操作ログ一を登録します。
         * @param {ContractLogInputModel} [contractLogInputModel] 登録する操作ログ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContractLog(contractLogInputModel?: ContractLogInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractLogViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContractLog(contractLogInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 操作ログ一覧を取得します。
         * @param {number} [page] ページ番号(0 ～)
         * @param {number} [perPage] ページに表示する件数(1 ～)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContractLogs(page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractLogsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContractLogs(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractLogsApi - factory interface
 * @export
 */
export const ContractLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary 操作ログ一を登録します。
         * @param {ContractLogInputModel} [contractLogInputModel] 登録する操作ログ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContractLog(contractLogInputModel?: ContractLogInputModel, options?: any): AxiosPromise<ContractLogViewModel> {
            return localVarFp.createContractLog(contractLogInputModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 操作ログ一覧を取得します。
         * @param {number} [page] ページ番号(0 ～)
         * @param {number} [perPage] ページに表示する件数(1 ～)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractLogs(page?: number, perPage?: number, options?: any): AxiosPromise<ContractLogsViewModel> {
            return localVarFp.listContractLogs(page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractLogsApi - object-oriented interface
 * @export
 * @class ContractLogsApi
 * @extends {BaseAPI}
 */
export class ContractLogsApi extends BaseAPI {
    /**
     * 
     * @summary 操作ログ一を登録します。
     * @param {ContractLogInputModel} [contractLogInputModel] 登録する操作ログ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractLogsApi
     */
    public createContractLog(contractLogInputModel?: ContractLogInputModel, options?: any) {
        return ContractLogsApiFp(this.configuration).createContractLog(contractLogInputModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 操作ログ一覧を取得します。
     * @param {number} [page] ページ番号(0 ～)
     * @param {number} [perPage] ページに表示する件数(1 ～)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractLogsApi
     */
    public listContractLogs(page?: number, perPage?: number, options?: any) {
        return ContractLogsApiFp(this.configuration).listContractLogs(page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractProductsApi - axios parameter creator
 * @export
 */
export const ContractProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 契約している製品の WebHook イベントの一覧を取得します。
         * @param {string} productId 製品 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractProductHookEvents: async (productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listContractProductHookEvents', 'productId', productId)
            const localVarPath = `/api/contract/products/{productId}/hook/events`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織に紐づくテナントが利用している製品の一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractProducts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織に紐づくテナントが利用している製品をアップグレードします。
         * @param {string} productId 製品 ID
         * @param {UpgradeProductInputModel} [upgradeProductInputModel] 製品のアップグレード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeContractProduct: async (productId: string, upgradeProductInputModel?: UpgradeProductInputModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('upgradeContractProduct', 'productId', productId)
            const localVarPath = `/api/contract/products/{productId}/version`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upgradeProductInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractProductsApi - functional programming interface
 * @export
 */
export const ContractProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 契約している製品の WebHook イベントの一覧を取得します。
         * @param {string} productId 製品 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContractProductHookEvents(productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HookEventViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContractProductHookEvents(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織に紐づくテナントが利用している製品の一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContractProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractProductViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContractProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織に紐づくテナントが利用している製品をアップグレードします。
         * @param {string} productId 製品 ID
         * @param {UpgradeProductInputModel} [upgradeProductInputModel] 製品のアップグレード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upgradeContractProduct(productId: string, upgradeProductInputModel?: UpgradeProductInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeContractProduct(productId, upgradeProductInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractProductsApi - factory interface
 * @export
 */
export const ContractProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary 契約している製品の WebHook イベントの一覧を取得します。
         * @param {string} productId 製品 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractProductHookEvents(productId: string, options?: any): AxiosPromise<Array<HookEventViewModel>> {
            return localVarFp.listContractProductHookEvents(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織に紐づくテナントが利用している製品の一覧を取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractProducts(options?: any): AxiosPromise<Array<ContractProductViewModel>> {
            return localVarFp.listContractProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織に紐づくテナントが利用している製品をアップグレードします。
         * @param {string} productId 製品 ID
         * @param {UpgradeProductInputModel} [upgradeProductInputModel] 製品のアップグレード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeContractProduct(productId: string, upgradeProductInputModel?: UpgradeProductInputModel, options?: any): AxiosPromise<void> {
            return localVarFp.upgradeContractProduct(productId, upgradeProductInputModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractProductsApi - object-oriented interface
 * @export
 * @class ContractProductsApi
 * @extends {BaseAPI}
 */
export class ContractProductsApi extends BaseAPI {
    /**
     * 
     * @summary 契約している製品の WebHook イベントの一覧を取得します。
     * @param {string} productId 製品 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractProductsApi
     */
    public listContractProductHookEvents(productId: string, options?: any) {
        return ContractProductsApiFp(this.configuration).listContractProductHookEvents(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織に紐づくテナントが利用している製品の一覧を取得します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractProductsApi
     */
    public listContractProducts(options?: any) {
        return ContractProductsApiFp(this.configuration).listContractProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織に紐づくテナントが利用している製品をアップグレードします。
     * @param {string} productId 製品 ID
     * @param {UpgradeProductInputModel} [upgradeProductInputModel] 製品のアップグレード情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractProductsApi
     */
    public upgradeContractProduct(productId: string, upgradeProductInputModel?: UpgradeProductInputModel, options?: any) {
        return ContractProductsApiFp(this.configuration).upgradeContractProduct(productId, upgradeProductInputModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractTenantApi - axios parameter creator
 * @export
 */
export const ContractTenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 大臣AXクラウドのパスワードポリシーを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordPolicy: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/tenant/password-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 大臣AXクラウドのシステム管理者のパスワードをリセットします。
         * @param {ResetAdministratorPasswordInputModel} [resetAdministratorPasswordInputModel] 新しいパスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAdministratorPassword: async (resetAdministratorPasswordInputModel?: ResetAdministratorPasswordInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract/tenant/accounts/administrator/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetAdministratorPasswordInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractTenantApi - functional programming interface
 * @export
 */
export const ContractTenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractTenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 大臣AXクラウドのパスワードポリシーを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPasswordPolicy(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordPolicyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPasswordPolicy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 大臣AXクラウドのシステム管理者のパスワードをリセットします。
         * @param {ResetAdministratorPasswordInputModel} [resetAdministratorPasswordInputModel] 新しいパスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAdministratorPassword(resetAdministratorPasswordInputModel?: ResetAdministratorPasswordInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetAdministratorPassword(resetAdministratorPasswordInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractTenantApi - factory interface
 * @export
 */
export const ContractTenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractTenantApiFp(configuration)
    return {
        /**
         * 
         * @summary 大臣AXクラウドのパスワードポリシーを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordPolicy(options?: any): AxiosPromise<PasswordPolicyViewModel> {
            return localVarFp.getPasswordPolicy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 大臣AXクラウドのシステム管理者のパスワードをリセットします。
         * @param {ResetAdministratorPasswordInputModel} [resetAdministratorPasswordInputModel] 新しいパスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAdministratorPassword(resetAdministratorPasswordInputModel?: ResetAdministratorPasswordInputModel, options?: any): AxiosPromise<void> {
            return localVarFp.resetAdministratorPassword(resetAdministratorPasswordInputModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractTenantApi - object-oriented interface
 * @export
 * @class ContractTenantApi
 * @extends {BaseAPI}
 */
export class ContractTenantApi extends BaseAPI {
    /**
     * 
     * @summary 大臣AXクラウドのパスワードポリシーを取得します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractTenantApi
     */
    public getPasswordPolicy(options?: any) {
        return ContractTenantApiFp(this.configuration).getPasswordPolicy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 大臣AXクラウドのシステム管理者のパスワードをリセットします。
     * @param {ResetAdministratorPasswordInputModel} [resetAdministratorPasswordInputModel] 新しいパスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractTenantApi
     */
    public resetAdministratorPassword(resetAdministratorPasswordInputModel?: ResetAdministratorPasswordInputModel, options?: any) {
        return ContractTenantApiFp(this.configuration).resetAdministratorPassword(resetAdministratorPasswordInputModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractsApi - axios parameter creator
 * @export
 */
export const ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 契約情報を取得します。
         * @param {string} contractId 契約 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById: async (contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getContractById', 'contractId', contractId)
            const localVarPath = `/api/admin/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractsApi - functional programming interface
 * @export
 */
export const ContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 契約情報を取得します。
         * @param {string} contractId 契約 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractById(contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractById(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractsApi - factory interface
 * @export
 */
export const ContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractsApiFp(configuration)
    return {
        /**
         * 
         * @summary 契約情報を取得します。
         * @param {string} contractId 契約 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(contractId: string, options?: any): AxiosPromise<ContractDetailsViewModel> {
            return localVarFp.getContractById(contractId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractsApi - object-oriented interface
 * @export
 * @class ContractsApi
 * @extends {BaseAPI}
 */
export class ContractsApi extends BaseAPI {
    /**
     * 
     * @summary 契約情報を取得します。
     * @param {string} contractId 契約 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public getContractById(contractId: string, options?: any) {
        return ContractsApiFp(this.configuration).getContractById(contractId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoragesApi - axios parameter creator
 * @export
 */
export const StoragesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 契約IDよりストレージ情報を取得します。
         * @param {string} contractId 契約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoragesByContractId: async (contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getStoragesByContractId', 'contractId', contractId)
            const localVarPath = `/api/admin/storages/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoragesApi - functional programming interface
 * @export
 */
export const StoragesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoragesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 契約IDよりストレージ情報を取得します。
         * @param {string} contractId 契約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoragesByContractId(contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoragesByContractId(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoragesApi - factory interface
 * @export
 */
export const StoragesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoragesApiFp(configuration)
    return {
        /**
         * 
         * @summary 契約IDよりストレージ情報を取得します。
         * @param {string} contractId 契約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoragesByContractId(contractId: string, options?: any): AxiosPromise<StorageInfoViewModel> {
            return localVarFp.getStoragesByContractId(contractId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoragesApi - object-oriented interface
 * @export
 * @class StoragesApi
 * @extends {BaseAPI}
 */
export class StoragesApi extends BaseAPI {
    /**
     * 
     * @summary 契約IDよりストレージ情報を取得します。
     * @param {string} contractId 契約ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoragesApi
     */
    public getStoragesByContractId(contractId: string, options?: any) {
        return StoragesApiFp(this.configuration).getStoragesByContractId(contractId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary テナント情報を登録します。
         * @param {RegisterTenantInputModel} [registerTenantInputModel] テナント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTenant: async (registerTenantInputModel?: RegisterTenantInputModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerTenantInputModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary テナント情報を登録します。
         * @param {RegisterTenantInputModel} [registerTenantInputModel] テナント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerTenant(registerTenantInputModel?: RegisterTenantInputModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerTenant(registerTenantInputModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantsApiFp(configuration)
    return {
        /**
         * 
         * @summary テナント情報を登録します。
         * @param {RegisterTenantInputModel} [registerTenantInputModel] テナント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTenant(registerTenantInputModel?: RegisterTenantInputModel, options?: any): AxiosPromise<TenantViewModel> {
            return localVarFp.registerTenant(registerTenantInputModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * 
     * @summary テナント情報を登録します。
     * @param {RegisterTenantInputModel} [registerTenantInputModel] テナント情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public registerTenant(registerTenantInputModel?: RegisterTenantInputModel, options?: any) {
        return TenantsApiFp(this.configuration).registerTenant(registerTenantInputModel, options).then((request) => request(this.axios, this.basePath));
    }
}


