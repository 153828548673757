import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {
  createStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  CssBaseline,
  Drawer,
} from "@material-ui/core";
import { PrivateRoute } from "./components/PrivateRoute";
import { NavBar } from "./components/NavBar";
import { SideMenu } from "./components/SideMenu";
import { Alerts } from "./features/alerts/Alerts";
import { LoginPage } from "./features/login/LoginPage";
import { ContractPage } from "./features/contract/ContractPage";
import { LogsPage } from "./features/contractLogs/LogsPage";
import { ProductsPage } from "./features/products/ProductsPage";
import { AccessKeyPage } from "./features/accessKeys/AccessKeyPage";
import { AdvancedAccessKeysPage } from "./features/accessKeys/AdvancedAccessKeysPage";
import { InitialPasswordPage } from "./features/login/InitialPasswordPage";
import { ForgotPasswordPage } from "./features/login/ForgotPasswordPage";
import { ForgotIdPage } from "./features/login/ForgotIdPage";
import { ResetPasswordPage } from "./features/login/ResetPasswordPage";
import { ContactsPage } from "./features/contacts/ContactsPage";
import { SecurityPage } from "./features/security/SecurityPage";
import { useAutoLogin } from "./features/login/hooks";
import packageJson from "../package.json";
import "./App.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
  }),
);

const App: React.FC = () => {
  const classes = useStyles();
  useAutoLogin();

  return (
    <div className={classes.root}>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage/>
          </Route>
          <Route path="/initial-password">
            <InitialPasswordPage />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Route path="/reset-password">
            <ResetPasswordPage />
          </Route>
          <Route path="/forgot-id">
            <ForgotIdPage />
          </Route>
          <Route path="/" exact>
            <LoginPage/>
          </Route>
          <Route path="/">
            <CssBaseline />
            <NavBar />
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.toolbar} />
              <SideMenu/>
            </Drawer>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Switch>
                <PrivateRoute path="/logs">
                  <LogsPage />
                </PrivateRoute>
                <PrivateRoute path="/products">
                  <ProductsPage />
                </PrivateRoute>
                <PrivateRoute path="/access-keys/advanced">
                  <AdvancedAccessKeysPage />
                </PrivateRoute>
                <PrivateRoute path="/access-keys">
                  <AccessKeyPage />
                </PrivateRoute>
                <PrivateRoute path="/contacts">
                  <ContactsPage />
                </PrivateRoute>
                <PrivateRoute path="/contract">
                  <ContractPage />
                </PrivateRoute>
                <PrivateRoute path="/security">
                  <SecurityPage />
                </PrivateRoute>
              </Switch>
            </main>
          </Route>
        </Switch>
      </Router>

      <Alerts />

      <div
        style={{
          position: 'fixed',
          display: 'inline',
          left: '10px',
          bottom: '10px',
          color: 'gray',
          fontSize: 'small',
        }}
      >
        v{packageJson.version}
      </div>
    </div>
  );
}

export default App;
