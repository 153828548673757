import React from 'react';
import {
  List,
} from '@material-ui/core';
import {
  Business,
  DesktopWindows,
  History,
  VpnKey,
  ContactMail,
  Security,
} from '@material-ui/icons';
import {
  ListItemLink,
} from './ListItemLink';

export function SideMenu() {
  return (
    <>
      <List component="nav">
        <ListItemLink
          to="/contract"
          primary="契約内容"
          icon={<Business />}
        />
        <ListItemLink
          to="/contacts"
          primary="連絡先情報"
          icon={<ContactMail />}
        />
        <ListItemLink
          to="/security"
          primary="セキュリティ"
          icon={<Security />}
        />
        <ListItemLink
          to="/products"
          primary="利用製品"
          icon={<DesktopWindows />}
        />
        <ListItemLink
          to="/access-keys"
          primary="アクセスキー"
          icon={<VpnKey />}
        />
        <ListItemLink
          to="/logs"
          primary="操作ログ"
          icon={<History />}
        />
      </List>
    </>
  );
}
