import React  from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {
  AccessKeyViewModel,
} from '../../api/axcloud';

export interface DeleteAccessKeyDialogProps {
  deletingAccessKey: AccessKeyViewModel | null;
  onCancel: () => void;
  onSubmit: (accessKey: AccessKeyViewModel) => void;
}

export function DeleteAccessKeyDialog(props: DeleteAccessKeyDialogProps) {
  const handleSubmit = () => {
    props.deletingAccessKey && props.onSubmit(props.deletingAccessKey);
  };

  return (
    <Dialog
      open={!!props.deletingAccessKey}
      onClose={props.onCancel}>
      <DialogTitle>アクセスキーの削除</DialogTitle>
      <DialogContent>
        <DialogContentText>
          「{props.deletingAccessKey?.name}」を削除してもいいですか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="secondary">
          削除
        </Button>
        <Button onClick={props.onCancel}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
}
