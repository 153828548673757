import React, {
  useCallback,
} from "react";
import {
  useHistory,
  Link,
} from "react-router-dom";
import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import {
  LockOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  useAppDispatch,
} from "../../app/store";
import {
  forgotContractId,
} from "./loginSlice";
import {
  isValidEmail,
} from "../../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface FormValues {
  email: string;
}

export const ForgotIdPage: React.FC = () => {
  const classes = useStyles();
  const {
    handleSubmit,
    formState,
    control,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  const history = useHistory();
  const dispatch = useAppDispatch();
  const onSubmit = useCallback((values: FormValues) => {
    dispatch(forgotContractId({
      email: values.email,
    })).then(() => {
      history.push("/");
    });
  }, [history, dispatch]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          契約IDを忘れたとき
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <p>
            登録されているメールアドレスに契約IDを送信します。
          </p>
          <Controller
            control={control}
            name="email"
            rules={{
              required: "メールアドレスは必須です。",
              validate: x => isValidEmail(x) ?
                true :
                "有効なメールアドレスではありません。",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                label="メールアドレス"
                type="email"
                id="email"
                autoComplete="email"
                error={!!formState.errors.email}
                helperText={formState.errors.email?.message}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formState.isValid || formState.isSubmitting}
          >
            送信
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="default"
            component={Link}
            to="/"
            disabled={formState.isSubmitting}
          >
            キャンセル
          </Button>
        </form>
      </div>
    </Container>
  );
};
