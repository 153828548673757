import dayjs from 'dayjs';

export function formatDateTime(
  date: Date | string | undefined,
  format?: string
): string {
  if (date) {
    if (!format) {
      format = "YYYY-MM-DD HH:mm:ss";
    }
    return dayjs(date).format(format);
  } else {
    return "";
  }
}

export function formatDate(
  date: Date | string | undefined
): string {
  return formatDateTime(date, "YYYY年MM月DD日");
}

const EMAIL_PATTERN =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isValidEmail(email?: string | null): boolean {
  if (!email) {
    return false;
  }
  if (!EMAIL_PATTERN.test(email)) {
    return false;
  }
  return true;
}

const ComplexityMinTypeCount = 3;
const AdministratorAccountSequence = [
  "Adm",
  "dmi",
  "min",
  "ini",
  "nis",
  "ist",
  "str",
  "tra",
  "rat",
  "ato",
  "tor",
];

export function isValidDaijinPassword(
  password: string,
  minLength: number,
  requireComplexity: boolean
): boolean | string {
  // 8 文字以上か
  if (password.length < minLength) {
    return `パスワードは ${minLength} 文字以上の長さが必要です。`;
  }

  // minLength 以上で複雑なパスワードが不要なら検証成功
  if (!requireComplexity) {
    return true;
  }

  let matchCount = 0;

  // アルファベット小文字を含んでいるか
  if (/[a-z]+/.test(password)) {
    matchCount++;
  }
  // アルファベット大文字を含んでいるか
  if (/[A-Z]+/.test(password)) {
    matchCount++;
  }
  // 数字を含んでいるか
  if (/\d+/.test(password)) {
    matchCount++;
  }
  // アルファベット大文字小文字数字以外を含んでいるか
  if (/[^a-zA-Z0-9]+/.test(password)) {
    matchCount++;
  }
  
  if (matchCount < ComplexityMinTypeCount) {
    return `新しいパスワードは半角英小文字大文字数字記号から${ComplexityMinTypeCount}種類以上使用する必要があります。`;
  }

  for(let item of AdministratorAccountSequence) {
    if (password.includes(item)) {
      return "Administrator に含まれる3文字以上連続する文字列は使用できません。";
    }
  }

  return true;
}

export function isValidPassword(password: string): boolean {
  // 8 文字以上か
  if (password.length < 8) {
    return false;
  }
  // アルファベット小文字を含んでいるか
  if (!/[a-z]+/.test(password)) {
    return false;
  }
  // アルファベット大文字を含んでいるか
  if (!/[A-Z]+/.test(password)) {
    return false;
  }
  // 数字を含んでいるか
  if (!/\d+/.test(password)) {
    return false;
  }
  // アルファベット大文字小文字数字以外を含んでいるか
  if (!/[^a-zA-Z0-9]+/.test(password)) {
    return false;
  }
  return true;
}

const CONTRACT_ID_PATTERN = /^AX[0-9]{6}$/;

export function isValidContractId(contractId?: string | null): boolean {
  if (!contractId) {
    return false;
  }
  if (!CONTRACT_ID_PATTERN.test(contractId)) {
    return false;
  }
  return true;
}
