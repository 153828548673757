import React, {
  useEffect,
} from 'react';
import {
  ContractTemplate,
} from './ContractTemplate';
import {
  useAppDispatch,
  useTypedSelector,
} from "../../app/store";
import {
  getContractDetails,
} from "./contractSlice";

export function ContractPage() {
  const state = useTypedSelector(x => ({
    ...x.login,
    ...x.contract,
  }));
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getContractDetails({
      accessToken: state.accessToken!
    }));
  }, [dispatch, state.accessToken]);

  return (
    <ContractTemplate contract={state.details} />
  );
}
