import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  ContractProductViewModel,
} from '../../api/axcloud';
import { Alert } from '@material-ui/lab';

export interface UpgradeProductDialogProps {
  product: ContractProductViewModel | null;
  onCancel: () => void;
  onSubmit: (product: ContractProductViewModel, version: string) => void;
}

export function UpgradeProductDialog({
  product,
  onCancel,
  onSubmit,
}: UpgradeProductDialogProps) {
  const [version, setVersion] = useState<string>("");
  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);
  const handleSubmit = useCallback(() => {
    if (product && version) {
      onSubmit(product, version);
    }
  }, [version, onSubmit, product]);

  useEffect(() => {
    setVersion(product?.version ?? "");
  }, [product]);

  return (
    <Dialog
      open={!!product}
      onClose={handleCancel}>
      <DialogTitle>
        {product?.productName} のバージョン変更
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          利用したいバージョンを選択してください。
        </DialogContentText>
        <Select
          fullWidth
          displayEmpty
          value={version}
          onChange={(e) => {
            if (e.target.value) {
              setVersion(e.target.value as string)
            } else {
              setVersion("");
            }
          }}
        >
          {product?.version && (
            <MenuItem value={product.version}>
              {product.version}
            </MenuItem>
          )}
          {
            product?.upgradableVersions &&
            product.upgradableVersions.map(v => (
              <MenuItem key={v} value={v}>{v}</MenuItem>
            ))}
        </Select>
        <Alert severity='warning' style={{marginTop: 10}}>
          変更後は以前のバージョンに戻すことができません。
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!version || product?.version === version}
        >
          変更
        </Button>
        <Button onClick={handleCancel}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
}
