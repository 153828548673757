import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

export interface DeleteEmailDialogProps {
  open: boolean;
  email?: string | null;
  onSubmit: () => void;
  onClose: () => void;
}

/** メールアドレス削除ダイアログ */
export const DeleteEmailDialog: React.VFC<DeleteEmailDialogProps> = ({
  open,
  email,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        メールアドレスの削除
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          「{email}」を削除してもよろしいですか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="default"
        >
          いいえ
        </Button>
        <Button
          color="secondary"
          onClick={onSubmit}
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};
