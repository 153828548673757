import React from "react";
import {
  Route,
  Redirect,
  RouteProps
} from "react-router-dom";
import {
  useTypedSelector,
} from '../app/store';

export function PrivateRoute({
  children,
  ...rest
 }: RouteProps) {
  const state = useTypedSelector(x => x.login);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.loggedIn ? !state.requiresPasswordChange ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/initial-password",
              state: { from: location }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
