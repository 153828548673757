import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from "axios";
import {
  AccessKeyViewModel,
  ContractAccessKeysApi,
  ProblemDetails,
} from '../../api/axcloud';
import {
  createConfiguration,
  createContractAccessKeysApi,
} from '../../api';
import {
  logout,
} from "../login/loginSlice";

type ListContractAccessKeysArgType = {
  accessToken: string;
};

export const listContractAccessKeys = createAsyncThunk<
  AccessKeyViewModel[],
  ListContractAccessKeysArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "accessKeys/listContractAccessKeys",
  async (arg, thunk) => {
    try {
      const config = createConfiguration(arg.accessToken);
      const api = new ContractAccessKeysApi(config);
      const res = await api.listContractAccessKeys();
      return res.data;
    } catch (error) {
      const axiosError = error as AxiosError<ProblemDetails>;
      if (!axiosError.response) {
        throw error;
      }
      return thunk.rejectWithValue(axiosError.response.data);
    }
  }
);

type RegisterContractAccessKeyArgType = {
  accessToken: string;
  name: string;
};

export const registerContractAccessKey = createAsyncThunk<
  AccessKeyViewModel,
  RegisterContractAccessKeyArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "accessKeys/registerContractAccessKey",
  async (arg, thunk) => {
    try {
      const config = createConfiguration(arg.accessToken);
      const api = new ContractAccessKeysApi(config);
      const res = await api.registerContractAccessKey({
        name: arg.name,
      })
      return res.data;
    } catch (error) {
      const axiosError = error as AxiosError<ProblemDetails>;
      if (!axiosError.response) {
        throw error;
      }
      return thunk.rejectWithValue(axiosError.response.data);
    }
  }
);

type UpdateContractAccessKeyArgType = {
  accessToken: string;
  id: string;
  name: string;
};

export const updateContractAccessKey = createAsyncThunk<
  AccessKeyViewModel,
  UpdateContractAccessKeyArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "accessKeys/updateContractAccessKey",
  async (arg, thunk) => {
    try {
      const config = createConfiguration(arg.accessToken);
      const api = new ContractAccessKeysApi(config);
      const res = await api.updateAccessKey(arg.id, {
        name: arg.name,
      });
      return res.data;
    } catch (error) {
      const axiosError = error as AxiosError<ProblemDetails>;
      if (!axiosError.response) {
        throw error;
      }
      return thunk.rejectWithValue(axiosError.response.data);
    }
  }
);

type DeleteContractAccessKeyArgType = {
  accessToken: string;
  id: string;
};

export const deleteContractAccessKey = createAsyncThunk<
  void,
  DeleteContractAccessKeyArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "accessKeys/deleteContractAccessKey",
  async (arg, thunk) => {
    try {
      const config = createConfiguration(arg.accessToken);
      const api = new ContractAccessKeysApi(config);
      await api.deleteAccessKey(arg.id);
    } catch (error) {
      const axiosError = error as AxiosError<ProblemDetails>;
      if (!axiosError.response) {
        throw error;
      }
      return thunk.rejectWithValue(axiosError.response.data);
    }
  }
);

type CopyContractAccessKeyArgType = {
  accessKey: AccessKeyViewModel;
};

export const copyContractAccessKey = createAsyncThunk<
  void,
  CopyContractAccessKeyArgType
>(
  "accessKeys/copyContractAccessKey",
  async (arg, thunk) => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(arg.accessKey.value!);
    } else {
      throw new Error("Clipboard API をサポートしていません。");
    }
  }
);

type GetContractPrimaryAccessKeyArgType = {
  accessToken: string;
};

export const getContractPrimaryAccessKey = createAsyncThunk<
  AccessKeyViewModel,
  GetContractPrimaryAccessKeyArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "accessKeys/getContractPrimaryAccessKey",
  async (arg, thunk) => {
    try {
      const api = createContractAccessKeysApi(arg.accessToken);
      const resp = await api.getPrimaryAccessKey();
      return resp.data;
    } catch (error) {
      const axiosError = error as AxiosError<ProblemDetails>;
      if (!axiosError.response) {
        throw error;
      }
      return thunk.rejectWithValue(axiosError.response.data);
    }
  }
);

type RegenerateContractPrimaryAccessKeyArgType = {
  accessToken: string;
};

export const regenerateContractPrimaryAccessKey = createAsyncThunk<
  AccessKeyViewModel,
  RegenerateContractPrimaryAccessKeyArgType,
  {
    rejectValue: ProblemDetails
  }
>(
  "accessKeys/regenerateContractPrimaryAccessKey",
  async (arg, thunk) => {
    try {
      const api = createContractAccessKeysApi(arg.accessToken);
      const resp = await api.regeneratePrimaryAccessKey();
      return resp.data;
    } catch (error) {
      const axiosError = error as AxiosError<ProblemDetails>;
      if (!axiosError.response) {
        throw error;
      }
      return thunk.rejectWithValue(axiosError.response.data);
    }
  }
);

export interface AccessKeysState {
  defaultAccessKey?: AccessKeyViewModel | null;
  openRegenerate: boolean;
  accessKeys: AccessKeyViewModel[];
  openNew: boolean;
  editingAccessKey: AccessKeyViewModel | null;
  deletingAccessKey: AccessKeyViewModel | null;
  showingAccessKey: AccessKeyViewModel | null;
}

const initialState: AccessKeysState = {
  defaultAccessKey: null,
  openRegenerate: false,
  accessKeys: [],
  openNew: false,
  editingAccessKey: null,
  deletingAccessKey: null,
  showingAccessKey: null,
};

export const accessKeysSlice = createSlice({
  name: "accessKeys",
  initialState,
  reducers: {
    openNew: state => ({
      ...state,
      openNew: true,
    }),
    cancelNew: state => ({
      ...state,
      openNew: false,
    }),
    openEdit: (state, action: PayloadAction<AccessKeyViewModel>) => ({
      ...state,
      editingAccessKey: action.payload,
    }),
    cancelEdit: state => ({
      ...state,
      editingAccessKey: null,
    }),
    openDelete: (state, action: PayloadAction<AccessKeyViewModel>) => ({
      ...state,
      deletingAccessKey: action.payload,
    }),
    cancelDelete: state => ({
      ...state,
      deletingAccessKey: null,
    }),
    openRegenerate: state => ({
      ...state,
      openRegenerate: true,
    }),
    cancelRegenerate: state => ({
      ...state,
      openRegenerate: false,
    }),
    openShow: (state, action: PayloadAction<AccessKeyViewModel>) => ({
      ...state,
      showingAccessKey: action.payload,
    }),
    cancelShow: state => ({
      ...state,
      showingAccessKey: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(listContractAccessKeys.fulfilled, (state, action) => {
      return {
        ...state,
        accessKeys: action.payload,
      };
    });
    builder.addCase(registerContractAccessKey.fulfilled, (state, action) => {
      return {
        ...state,
        openNew: false,
        accessKeys: [
          ...state.accessKeys,
          action.payload,
        ],
      };
    });
    builder.addCase(updateContractAccessKey.fulfilled, (state, action) => {
      return {
        ...state,
        editingAccessKey: null,
        accessKeys: state.accessKeys.map(x => {
          if (x.id === action.payload.id) {
            return action.payload;
          } else {
            return x;
          }
        }),
      };
    });
    builder.addCase(deleteContractAccessKey.fulfilled, (state, action) => {
      return {
        ...state,
        deletingAccessKey: null,
        accessKeys: state.accessKeys.filter(x => x.id !== action.meta.arg.id),
      };
    });
    builder.addCase(getContractPrimaryAccessKey.fulfilled, (state, action) => {
      return {
        ...state,
        defaultAccessKey: action.payload,
      };
    });
    builder.addCase(regenerateContractPrimaryAccessKey.fulfilled, (state, action) => {
      return {
        ...state,
        defaultAccessKey: action.payload,
        openRegenerate: false,
      };
    });
    builder.addCase(regenerateContractPrimaryAccessKey.rejected, (state, action) => {
      return {
        ...state,
        openRegenerate: false,
      };
    });
    builder.addCase(logout.fulfilled, (state, action) => ({
      ...initialState,
    }));
  },
});

export const {
  openNew,
  openDelete,
  openEdit,
  cancelDelete,
  cancelEdit,
  cancelNew,
  openRegenerate,
  cancelRegenerate,
  openShow,
  cancelShow,
} = accessKeysSlice.actions;

export default accessKeysSlice.reducer;
