import React from 'react';
import { Link } from "react-router-dom";
import {
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Button,
  ButtonGroup,
  TextField,
} from '@material-ui/core';
import {
  RegenerateAccessKeyDialog,
} from "./RegenerateAccessKeyDialog";
import {
  AccessKeyViewModel,
} from '../../api/axcloud';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
  }),
);

export interface AccessKeyTemplateProps {
  companyName?: string | null;
  defaultAccessKey?: AccessKeyViewModel | null;
  onCopy?: () => void;
  openRegenerate?: boolean;
  onOpenRegenerate?: () => void;
  onSubmitRegenerate?: () => void;
  onCancelRegenerate?: () => void;
}

export const AccessKeyTemplate: React.FC<AccessKeyTemplateProps> = ({
  companyName,
  defaultAccessKey,
  openRegenerate,
  onCopy,
  onOpenRegenerate,
  onSubmitRegenerate,
  onCancelRegenerate,
}) => {
  const classes = useStyles({});

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
            アクセスキー
        </Typography>

        <section style={{ marginTop: "20px" }}>
          <TextField
            label={`${companyName}用アクセスキー`}
            fullWidth
            variant="filled"
            multiline
            defaultValue={defaultAccessKey?.value}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: true,
            }}
          />
        </section>

        <section style={{ marginTop: "20px" }}>
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              disabled={!defaultAccessKey}
              onClick={onCopy}
            >
              コピー
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={!defaultAccessKey}
              onClick={onOpenRegenerate}
            >
              再生成
            </Button>
          </ButtonGroup>
        </section>

        <footer style={{ marginTop: "20px" }}>
          <Button
            color="primary"
            component={Link}
            to="/access-keys/advanced"
          >
            高度な設定
          </Button>
        </footer>
      </Paper>

      <RegenerateAccessKeyDialog
        open={!!openRegenerate}
        onSubmit={onSubmitRegenerate}
        onCancel={onCancelRegenerate}
      />
    </>
  );
};
