import React from 'react';
import {
  Snackbar,
} from '@material-ui/core';
import {
  Alert,
  Color,
} from '@material-ui/lab';

export interface AlertSnackbarProps {
  open: boolean;
  message?: string | null;
  autoHideDuration?: number;
  severity?: Color;
  onClose: () => void;
}

export function AlertSnackbar(props: AlertSnackbarProps) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      onClose={props.onClose}
    >
      <Alert
        onClose={props.onClose}
        severity={props.severity}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
} 
