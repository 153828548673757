import React, {
  useCallback,
} from "react";
import {
  createStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  TableRow,
  TableCell,
  Button,
  ButtonGroup,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionCell: {
      width: "20em",
    },
  }),
);

export interface ContactRowProps {
  title?: string;
  email?: string | null;
  primary?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onTest?: () => void;
}

export const ContactRow: React.VFC<ContactRowProps> = ({
  title,
  email,
  primary,
  onEdit,
  onDelete,
  onTest,
}) => {
  const classes = useStyles({});
  const handleEdit = useCallback(() => {
    onEdit && onEdit();
  }, [onEdit]);
  const handleDelete = useCallback(() => {
    onDelete && onDelete();
  }, [onDelete]);
  const handleTest = useCallback(() => {
    onTest && onTest();
  }, [onTest]);

  return (
    <TableRow>
      <TableCell>
        {title}
      </TableCell>
      <TableCell>
        {email}
      </TableCell>
      <TableCell className={classes.actionCell}>
        <ButtonGroup>
          <Button
            color="default"
            variant="contained"
            onClick={handleTest}
            disabled={!email}
          >
            テスト送信
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleEdit}
          >
            変更
          </Button>
          {!primary && (
            <Button
              color="secondary"
              variant="contained"
              disabled={!email}
              onClick={handleDelete}
            >
              削除
            </Button>
          )}
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};
