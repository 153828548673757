import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  contractLogin,
  changeContractPassword,
  forgotContractId,
  forgotPassword,
  resetContractPassword,
  logout,
} from "../login/loginSlice";
import {
  registerContractAccessKey,
  deleteContractAccessKey,
  updateContractAccessKey,
  copyContractAccessKey,
  regenerateContractPrimaryAccessKey,
} from "../accessKeys/accessKeysSlice";
import {
  upgradeContractProduct,
} from "../products/productsSlice";
import {
  updateEmail,
  updateSubEmail1,
  updateSubEmail2,
  deleteSubEmail1,
  deleteSubEmail2,
  testEmail,
} from "../contacts/contactsSlice";
import {
  resetAdministratorPassword,
  resolvePasswordPolicy,
} from "../security/securitySlice";

export interface AlertsState {
  showSuccess: boolean;
  successMessage: string | null;
  showError: boolean;
  errorMessage: string | null;
}

const initialState: AlertsState = {
  showSuccess: false,
  showError: false,
  successMessage: null,
  errorMessage: null,
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    showSuccess: (state, action: PayloadAction<string>) => ({
      ...state,
      showSuccess: true,
      successMessage: action.payload,
      showError: false,
      errorMessage: null,
    }),
    showError: (state, action: PayloadAction<string>) => ({
      ...state,
      showError: true,
      showSuccess: false,
      successMessage: null,
      errorMessage: action.payload,
    }),
    hideSuccess: (state) => ({
      ...state,
      showSuccess: false,
      successMessage: null,
    }),
    hideError: (state) => ({
      ...state,
      showError: false,
      errorMessage: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(contractLogin.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "ログインに失敗しました。",
    }));

    builder.addCase(forgotContractId.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: `${action.meta.arg.email} に契約 ID を送信しました。`,
    }));
    builder.addCase(forgotContractId.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "契約IDを送信できませんでした。",
    }));

    builder.addCase(forgotPassword.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: `${action.meta.arg.email} にパスワードリセットトークンを送信しました。`,
    }));
    builder.addCase(forgotPassword.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "パスワードリセットトークンを送信できませんでした。",
    }));

    builder.addCase(registerContractAccessKey.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "アクセスキーを登録しました。",
    }));
    builder.addCase(registerContractAccessKey.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "アクセスキーの登録に失敗しました。",
    }));

    builder.addCase(updateContractAccessKey.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "アクセスキーを更新しました。",
    }));
    builder.addCase(updateContractAccessKey.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "アクセスキーの更新に失敗しました。",
    }));

    builder.addCase(deleteContractAccessKey.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "アクセスキーを削除しました。",
    }));
    builder.addCase(deleteContractAccessKey.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "アクセスキーの削除に失敗しました。",
    }));

    builder.addCase(copyContractAccessKey.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "アクセスキーをコピーしました。",
    }));

    builder.addCase(regenerateContractPrimaryAccessKey.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "アクセスキーを再生成しました。",
    }));

    builder.addCase(upgradeContractProduct.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "アップグレードしました。",
    }));
    builder.addCase(upgradeContractProduct.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "アップグレードに失敗しました。",
    }));

    builder.addCase(changeContractPassword.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "パスワードを変更しました。",
    }));
    builder.addCase(changeContractPassword.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "パスワードの変更に失敗しました。",
    }));

    builder.addCase(resetContractPassword.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "パスワードをリセットしました。",
    }));
    builder.addCase(resetContractPassword.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "パスワードのリセットに失敗しました。",
    }));

    builder.addCase(updateEmail.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "メールアドレスを変更しました。",
    }));
    builder.addCase(updateEmail.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "メールアドレスの変更に失敗しました。",
    }));

    builder.addCase(updateSubEmail1.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "メールアドレス(予備1)を変更しました。",
    }));
    builder.addCase(updateSubEmail1.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "メールアドレス(予備1)の変更に失敗しました。",
    }));

    builder.addCase(updateSubEmail2.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "メールアドレス(予備2)を変更しました。",
    }));
    builder.addCase(updateSubEmail2.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "メールアドレス(予備2)の変更に失敗しました。",
    }));

    builder.addCase(deleteSubEmail1.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "メールアドレス(予備1)を削除しました。",
    }));
    builder.addCase(deleteSubEmail1.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "メールアドレス(予備1)の削除に失敗しました。",
    }));

    builder.addCase(deleteSubEmail2.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "メールアドレス(予備2)を削除しました。",
    }));
    builder.addCase(deleteSubEmail2.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "メールアドレス(予備2)の削除に失敗しました。",
    }));

    builder.addCase(testEmail.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: `${action.meta.arg.email} 宛にテストメールを送信しました。`,
    }));
    builder.addCase(testEmail.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "テストメールの送信に失敗しました。",
    }));

    builder.addCase(resolvePasswordPolicy.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "パスワードポリシーの取得に失敗しました。",
    }));

    builder.addCase(resetAdministratorPassword.fulfilled, (state, action) => ({
      ...state,
      showSuccess: true,
      successMessage: "システム管理者のパスワードをリセットしました。",
    }));
    builder.addCase(resetAdministratorPassword.rejected, (state, action) => ({
      ...state,
      showError: true,
      errorMessage: action.payload?.detail ?? "システム管理者のパスワードのリセットに失敗しました。",
    }));
    builder.addCase(logout.fulfilled, (state, action) => ({
      ...initialState,
    }));
  },
});

export const {
  showSuccess,
  showError,
  hideSuccess,
  hideError,
} = alertsSlice.actions;

export default alertsSlice.reducer;
