import React, {
  useState,
  useCallback,
} from "react";
import {
  createStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Alert,
} from "@material-ui/lab";
import {
  ContactRow,
} from "./ContactRow";
import {
  EditEmailDialog,
  EditEmailDialogValues,
} from "./EditEmailDialog";
import {
  DeleteEmailDialog,
} from "./DeleteEmailDialog";
import {
  TestEmailDialog,
  TestEmailDialogValues,
} from "./TestEmailDialog";
import {
  useAppDispatch,
  useTypedSelector,
} from "../../app/store";
import {
  updateEmail,
  updateSubEmail1,
  updateSubEmail2,
  deleteSubEmail1,
  deleteSubEmail2,
  testEmail,
} from "./contactsSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      marginBottom: 50,
    },
    footer: {
      marginTop: 20,
    },
  }),
);

interface ContactsPageState {
  editingEmail: boolean;
  editingSubEmail1: boolean;
  editingSubEmail2: boolean;
  deletingSubEmail1: boolean;
  deletingSubEmail2: boolean;
  testingEmail: boolean;
  testingSubEmail1: boolean;
  testingSubEmail2: boolean;
}

/**
 * 連絡先情報ページ
 */
export const ContactsPage: React.VFC = () => {
  const classes = useStyles();

  const {
    contract,
    accessToken,
  } = useTypedSelector(x=> ({
    contract: x.contract.details,
    accessToken: x.login.accessToken,
  }));
  const dispatch = useAppDispatch();

  const [state, setState] = useState<ContactsPageState>({
    editingEmail: false,
    editingSubEmail1: false,
    editingSubEmail2: false,
    deletingSubEmail1: false,
    deletingSubEmail2: false,
    testingEmail: false,
    testingSubEmail1: false,
    testingSubEmail2: false,
  });
  const handleClose = useCallback(() => {
    setState({
      editingEmail: false,
      editingSubEmail1: false,
      editingSubEmail2: false,
      deletingSubEmail1: false,
      deletingSubEmail2: false,
      testingEmail: false,
      testingSubEmail1: false,
      testingSubEmail2: false,
    });
  }, [setState]);
  const handleEditEmail = useCallback(() => {
    setState(state => ({
      ...state,
      editingEmail: true,
    }));
  }, [setState]);
  const handleEditSubEmail1 = useCallback(() => {
    setState(state => ({
      ...state,
      editingSubEmail1: true,
    }));
  }, [setState]);
  const handleEditSubEmail2 = useCallback(() => {
    setState(state => ({
      ...state,
      editingSubEmail2: true,
    }));
  }, [setState]);
  const handleDeleteSubEmail1 = useCallback(() => {
    setState(state => ({
      ...state,
      deletingSubEmail1: true,
    }));
  }, [setState]);
  const handleDeleteSubEmail2 = useCallback(() => {
    setState(state => ({
      ...state,
      deletingSubEmail2: true,
    }));
  }, [setState]);
  const handleTestEmail = useCallback(() => {
    setState(state => ({
      ...state,
      testingEmail: true,
    }));
  }, [setState]);
  const handleTestSubEmail1 = useCallback(() => {
    setState(state => ({
      ...state,
      testingSubEmail1: true,
    }));
  }, [setState]);
  const handleTestSubEmail2 = useCallback(() => {
    setState(state => ({
      ...state,
      testingSubEmail2: true,
    }));
  }, [setState]);
  const handleSubmitEditEmail = useCallback((values: EditEmailDialogValues) => {
    dispatch(updateEmail({
      accessToken: accessToken!,
      email: values.newEmail,
    }));
    handleClose();
  }, [handleClose, dispatch, accessToken]);
  const handleSubmitEditSubEmail1 = useCallback((values: EditEmailDialogValues) => {
    dispatch(updateSubEmail1({
      accessToken: accessToken!,
      email: values.newEmail,
    }));
    handleClose();
  }, [handleClose, dispatch, accessToken]);
  const handleSubmitEditSubEmail2 = useCallback((values: EditEmailDialogValues) => {
    dispatch(updateSubEmail2({
      accessToken: accessToken!,
      email: values.newEmail,
    }));
    handleClose();
  }, [handleClose, dispatch, accessToken]);
  const handleSubmitDeleteSubEmail1 = useCallback(() => {
    dispatch(deleteSubEmail1({
      accessToken: accessToken!,
    }));
    handleClose();
  }, [handleClose, dispatch, accessToken]);
  const handleSubmitDeleteSubEmail2 = useCallback(() => {
    dispatch(deleteSubEmail2({
      accessToken: accessToken!,
    }));
    handleClose();
  }, [handleClose, dispatch, accessToken]);
  const handleSubmitTestEmail = useCallback((values: TestEmailDialogValues) => {
    dispatch(testEmail({
      accessToken: accessToken!,
      email: values.email,
    }));
    handleClose();
  }, [handleClose, dispatch, accessToken]);

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          連絡先情報
        </Typography>

        <Typography>
          大臣AXクラウドに関する重要なお知らせを配信いたしますので、<br/>
          日常的に確認が可能なメールアドレスを指定してください。
        </Typography>

        <Alert severity="info">
          法改正やメンテナンスなどの情報は「メイン、予備１、予備２」の全てのアドレスに対して配信されます。<br/>
          パスワード再発行などログインに関する重要なお知らせは「メイン」のアドレスのみに配信されます。
        </Alert>

        <Table>
          <TableBody>
            <ContactRow
              title="メールアドレス（メイン）"
              primary
              email={contract?.email}
              onEdit={handleEditEmail}
              onTest={handleTestEmail}
            />
            <ContactRow
              title="メールアドレス（予備１）"
              email={contract?.subEmail1}
              onEdit={handleEditSubEmail1}
              onDelete={handleDeleteSubEmail1}
              onTest={handleTestSubEmail1}
            />
            <ContactRow
              title="メールアドレス（予備２）"
              email={contract?.subEmail2}
              onEdit={handleEditSubEmail2}
              onDelete={handleDeleteSubEmail2}
              onTest={handleTestSubEmail2}
            />
          </TableBody>
        </Table>

        <Typography
          className={classes.footer}
        >
          大臣シリーズに関するお役立ち情報を掲載したメールマガジンも配信しておりますので、<br/>
          よろしければ併せてご登録ください。
          <a
            href="http://www.ohken.co.jp/support/mmregister.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            ご登録はこちら。
          </a>
        </Typography>
      </Paper>

      <EditEmailDialog
        open={state.editingEmail}
        email={contract?.email}
        onClose={handleClose}
        onSubmit={handleSubmitEditEmail}
      />
      <EditEmailDialog
        open={state.editingSubEmail1}
        email={contract?.subEmail1}
        onClose={handleClose}
        onSubmit={handleSubmitEditSubEmail1}
      />
      <EditEmailDialog
        open={state.editingSubEmail2}
        email={contract?.subEmail2}
        onClose={handleClose}
        onSubmit={handleSubmitEditSubEmail2}
      />

      <DeleteEmailDialog
        open={state.deletingSubEmail1}
        email={contract?.subEmail1}
        onClose={handleClose}
        onSubmit={handleSubmitDeleteSubEmail1}
      />
      <DeleteEmailDialog
        open={state.deletingSubEmail2}
        email={contract?.subEmail2}
        onClose={handleClose}
        onSubmit={handleSubmitDeleteSubEmail2}
      />

      <TestEmailDialog
        open={state.testingEmail}
        email={contract?.email}
        onClose={handleClose}
        onSubmit={handleSubmitTestEmail}
      />
      <TestEmailDialog
        open={state.testingSubEmail1}
        email={contract?.subEmail1}
        onClose={handleClose}
        onSubmit={handleSubmitTestEmail}
      />
      <TestEmailDialog
        open={state.testingSubEmail2}
        email={contract?.subEmail2}
        onClose={handleClose}
        onSubmit={handleSubmitTestEmail}
      />
    </>
  );
};
