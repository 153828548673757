import React from "react";
import { Redirect } from "react-router-dom";
import {
  InitialPasswordTemplate,
} from "./InitialPasswordTemplate";
import {
  useTypedSelector,
  useAppDispatch,
} from "../../app/store";
import {
  changeContractPassword,
} from "./loginSlice";

export function InitialPasswordPage() {
  const state = useTypedSelector(x => x.login);
  const dispatch = useAppDispatch();

  return state.loggedIn ? state.requiresPasswordChange ? (
    <InitialPasswordTemplate
      onSubmit={(values) => {
        dispatch(changeContractPassword({
          accessToken: state.accessToken!,
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        }));
      }}
    />
  ) : (
    <Redirect to="/login" />
  ) : (
    <Redirect to="/contract" />
  );
}
