import React from "react";
import {
  v4 as uuidv4,
} from "uuid";
import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  createStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  isValidEmail,
} from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginTop: 10,
    },
  }),
);

export interface EditEmailDialogValues {
  newEmail: string;
  newEmailConfirmation: string;
}

export interface EditEmailDialogProps {
  open: boolean;
  email?: string | null;
  onSubmit: (values: EditEmailDialogValues) => void;
  onClose: () => void;
}

/** メールアドレス変更ダイアログ */
export const EditEmailDialog: React.VFC<EditEmailDialogProps> = ({
  open,
  email,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const formId = uuidv4();
  const {
    handleSubmit,
    watch,
    control,
    formState: {
      isValid,
      errors,
    },
  } = useForm<EditEmailDialogValues>({
    mode: "all",
  });
  const {
    newEmail,
  } = watch();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        メールアドレスの変更
      </DialogTitle>
      <DialogContent>
        <TextField
          label="現在のメールアドレス"
          type="email"
          className={classes.input}
          fullWidth
          variant="filled"
          placeholder="未設定"
          value={email}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            readOnly: true,
          }}
        />
        <form
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="newEmail"
            control={control}
            rules={{
              required: "新しいメールアドレスは必須です。",
              validate: (value) => {
                return isValidEmail(value) ? true : "有効なメールアドレスではありません。";
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="新しいメールアドレス"
                type="email"
                className={classes.input}
                autoFocus
                fullWidth
                helperText={errors.newEmail?.message}
                error={!!errors.newEmail}
              />
            )}
          />
          <Controller
            name="newEmailConfirmation"
            control={control}
            rules={{
              required: "新しいメールアドレス(確認用)は必須です。",
              validate: (value) => {
                return newEmail === value ?
                  true :
                  "新しいメールアドレスと新しいメールアドレス(確認用)が一致していません。";
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="新しいメールアドレス(確認用)"
                type="email"
                className={classes.input}
                fullWidth
                helperText={errors.newEmailConfirmation?.message}
                error={!!errors.newEmailConfirmation}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="default"
        >
          キャンセル
        </Button>
        <Button
          color="primary"
          type="submit"
          form={formId}
          disabled={!isValid}
        >
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
};
