import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  ContractLogViewModel,
  ContractLogsApi,
} from '../../api/axcloud';
import {
  createConfiguration,
} from '../../api';
import {
  logout,
} from "../login/loginSlice";

export interface ContractLogsState {
  logs: ContractLogViewModel[];
  page: number;
  perPage: number;
  totalCount: number;
}

type ListContractLogsArgType = {
  page: number;
  perPage: number;
  accessToken: string;
};

type ListContractLogsReturnType = {
  logs: ContractLogViewModel[];
  totalCount: number;
};

export const listContractLogs = createAsyncThunk<ListContractLogsReturnType, ListContractLogsArgType>(
  "logs/listContractLogs",
  async (arg, thunk): Promise<ListContractLogsReturnType> => {
    const config = createConfiguration(arg.accessToken);
    const api = new ContractLogsApi(config);
    const res = await api.listContractLogs(arg.page, arg.perPage);
    return {
      logs: res.data.logs!,
      totalCount: res.data.totalCount!,
    };
  }
);

const initialState: ContractLogsState = {
  logs: [],
  page: 0,
  perPage: 50,
  totalCount: -1,
};

export const contractLogsSlice = createSlice({
  name: "contractLogs",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(listContractLogs.fulfilled, (state, action) => ({
      ...state,
      page: action.meta.arg.page,
      perPage: action.meta.arg.perPage,
      logs: action.payload.logs,
      totalCount: action.payload.totalCount,
    }));
    builder.addCase(logout.fulfilled, (state, action) => ({
      ...initialState,
    }));
  },
});

export default contractLogsSlice.reducer;
