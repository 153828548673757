import React, {
  useCallback,
} from "react";
import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  useHistory,
  Link,
} from "react-router-dom";
import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import {
  LockOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  useAppDispatch,
} from "../../app/store";
import {
  forgotPassword,
} from "./loginSlice";
import {
  isProblemDetails,
} from "../../api";
import {
  isValidEmail,
  isValidContractId,
} from "../../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface FormValues {
  contractId: string;
  email: string;
}

export const ForgotPasswordPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  const onSubmit = useCallback(async (values: FormValues) => {
    const res = await dispatch(forgotPassword({
      contractId: values.contractId,
      email: values.email,
    }));
    if (!isProblemDetails(res.payload)) {
      history.push("/reset-password");
    }
  }, [dispatch, history]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          パスワードのリセット
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <p>
            登録されているメールアドレスにパスワードリセットトークンを送信します。
          </p>
          <Controller
            control={control}
            name="contractId"
            rules={{
              required: "契約IDは必須です。",
              validate: x => isValidContractId(x) ?
                true :
                "有効な契約IDではありません。",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="契約ID"
                id="contractId"
                autoComplete="contract-id"
                error={!!formState.errors.contractId}
                helperText={formState.errors.contractId?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: "メールアドレスは必須です。",
              validate: x => isValidEmail(x) ?
                true :
                "有効なメールアドレスではありません。",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="メールアドレス"
                type="email"
                id="email"
                autoComplete="email"
                error={!!formState.errors.email}
                helperText={formState.errors.email?.message}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formState.isValid || formState.isSubmitting}
          >
            送信
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="default"
            disabled={formState.isSubmitting}
            component={Link}
            to="/"
          >
            キャンセル
          </Button>
        </form>
      </div>
    </Container>
  );
};
