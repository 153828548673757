import React from 'react';
import {
  Avatar,
  CssBaseline,
  Typography,
  Container,
} from '@material-ui/core';
import {
  LockOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  InitialPasswordForm,
  InitialPasswordFormValues,
} from "./InitialPasswordForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  }
}));

export interface InitialPasswordTemplateProps {
  onSubmit?: (values: InitialPasswordFormValues) => void;
}

export const InitialPasswordTemplate: React.VFC<InitialPasswordTemplateProps> = ({
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          初回ログインのためパスワードを変更する必要があります
        </Typography>
        <InitialPasswordForm
          className={classes.form}
          onSubmit={onSubmit}
        />
      </div>
    </Container>
  );
};
