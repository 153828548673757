import React, {
  useCallback,
} from "react";
import {
  Redirect,
} from "react-router-dom";
import {
  useTypedSelector,
  useAppDispatch,
} from "../../app/store";
import {
  contractLogin,
} from "./loginSlice";
import {
  LoginTemplate,
} from "./LoginTemplate";

export function LoginPage() {
  const state = useTypedSelector(x => x.login);
  const dispatch = useAppDispatch();
  const onLogin = useCallback(async (contractId: string, password: string) => {
    await dispatch(contractLogin({
      contractId,
      password,
    }));
  }, [dispatch]);

  return state.loggedIn ? (
    <Redirect to="/contract"/>
  ) : (
    <LoginTemplate
      onLogin={onLogin}
    />
  );
}
